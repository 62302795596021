import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { MdEditNote, MdOutlineLock } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import EditUserContainer from "../../components/editUser";
import Modal from "../../components/common/modal";
import { validateEmail } from "../../utils";

import {
    getUsersList,
    editUser,
    addUser,
    deleteUser,
    udpateUserPassword,
    sendInvite,
    getOkcrUsersList,
} from "../../actions/userManagementAction";

function UserManagement({
    getUsersList,
    addUser,
    editUser,
    deleteUser,
    udpateUserPassword,
    users,
    okcrUsers,
    theme,
    sendInvite,
    getOkcrUsersList,
    loading,
}) {
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState('');
    const [inviteEmail, setInviteEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [show, setShow] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [modalBody, setModalBody] = useState('');
    const [modalHeader, setModalHeader] = useState('');
    const [modalFooter, setModalFooter] = useState('');

    const closeModal = () => {
        setPassword('');
        setShow(false);
    }

    const closeInviteModal = () => {
      setInviteEmail('');
      setIsEmailValid(true);
      setShowInviteUser(false);
    }

    const cancelButton = (
      <button className="btn btn-info" onClick={() => setShow(false)}>
        Cancel
      </button>
    );

    useEffect(() => {
        getUsersList();
        getOkcrUsersList();
    }, [getUsersList, getOkcrUsersList]);

    const showConfirmationBox = (username) => {
        setModalHeader('Confirm Deletion');
        setModalBody('Are you sure you want to delete this User?');
        setModalFooter(
            <div>
                <button
                    className="btn btn-primary mr-3"
                    onClick={() => {
                        deleteUser({ username });
                        setShow(false);
                    }}
                >
                    Yes
                </button>
                {cancelButton}
            </div>
        );
        setShow(true);
    };

    const cols = [
        {
            headerName: "First Name",
            field: "fn",
            maxWidth: 200,
            wrapHeaderText: true,
        },
        {
            headerName: "Last Name",
            field: "ln",
            maxWidth: 200,
            wrapHeaderText: true,
        },
        {
            headerName: "Username",
            field: "username",
            maxWidth: 200,
            wrapHeaderText: true,
        },
        {
            headerName: "Company",
            field: "co",
            flex: 1,
            wrapHeaderText: true,
        },
        {
            headerName: "Address",
            field: "ad",
            flex: 1,
            wrapHeaderText: true,
        },
        {
            headerName: "Phone Number",
            field: "ph",
            maxWidth: 200,
            wrapHeaderText: true,
        },
        {
            headerName: "Is Admin",
            field: "iau",
            maxWidth: 200,
            wrapHeaderText: true,
            cellRenderer: ({ value }) => value ? 'Yes' : 'No'
        },
        {
            headerName: "",
            resizable: false,
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: ({ data }) => (
                <>
                    <MdEditNote
                        className="btn-icon"
                        onClick={() => {
                            editUser(data);
                            setEdit(true);
                        }}
                    />
                    <MdOutlineLock
                        className="btn-icon"
                        onClick={() => {
                          setUser(data);
                          setPassword('');
                          setShowResetPassword(true);
                        }}
                    />
                    <IoMdClose
                        className="btn-icon"
                        onClick={() => showConfirmationBox(data.username)}
                    />
                </>
            ),
        },
    ];

    const okcrCols = [
      {
        headerName: "Haloapp Username",
        field: "aun",
        // maxWidth: 200,
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "Date Time",
        field: "rt",
        wrapHeaderText: true,
        // width: 180,
        flex: 1,
        filter: "agDateColumnFilter",
        cellRenderer: ({ value }) =>
          value instanceof Date
            ? value.toLocaleString("en-US", { timeZone: "UTC" })
            : "",
      },
      {
        headerName: "OKCR Username",
        field: "oun",
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "Total Instruments",
        field: "ti",
        flex: 1,
        // maxWidth: 200,
        wrapHeaderText: true,
      },
    ];

    return (
      <div
        className={`dashboard${
          theme === "dark" ? "-dark" : ""
        } d-flex flex-column`}
      >
        <NavBarContainer redirect={() => navigate("/dash-board")} />
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "20px",
            zIndex: 1,
          }}
        >
          {/* <span
            className="user-count"
            style={{ marginRight: "20px", marginTop: "8px" }}
          >
            Users: {users.length}
          </span> */}
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowInviteUser(true);
            }}
          >
            Invite User
          </button>
        </div>
        <div className="content d-flex flex-column">
          <div className="data d-flex flex-column">
            <Tabs defaultActiveKey="users">
              <Tab eventKey="users" title="Users">
                <div className="digital d-flex flex-column">
                  <div style={{ height: '30px' }}>
                    <span style={{ fontSize: '16px', fontWeight: 'bold'}}>
                      {loading
                        ? "Fetching..."
                        : `User Count: ${users.length}`}
                    </span>
                  </div>
                  {!edit && (
                    <button
                      className="btn btn-primary add"
                      onClick={() => {
                        addUser();
                        setEdit(true);
                      }}
                    >
                      +
                    </button>
                  )}
                  <Table cols={cols} data={users} />
                  {edit && (
                    <EditUserContainer onCancel={() => setEdit(false)} />
                  )}
                </div>
              </Tab>
              <Tab eventKey="okcr" title="OKCR">
                <div className="digital d-flex flex-column">
                  <Table cols={okcrCols} data={okcrUsers} />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        {/* Set password causing issue while reusing modal */}
        <Modal
          showModal={show}
          header={modalHeader}
          html={modalBody}
          footerHtml={modalFooter}
          onHide={closeModal}
          theme={theme}
        />
        <Modal
          showModal={showResetPassword}
          header="Reset Password"
          theme={theme}
          html={
            <div className="mb-3">
              <input
                placeholder="Please enter new password"
                name="password"
                className="form-control bg-transparent"
                type="text"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          }
          footerHtml={
            <div>
              <button
                className="btn btn-primary mr-3"
                onClick={() => {
                  udpateUserPassword({
                    username: user.username,
                    pwd: password,
                  });
                  setShowResetPassword(false);
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-info"
                onClick={() => setShowResetPassword(false)}
              >
                Cancel
              </button>
            </div>
          }
          onHide={() => setShowResetPassword(false)}
        />
        <Modal
          showModal={showInviteUser}
          header="Invite User"
          theme={theme}
          animation={true}
          html={
            <div className="mb-3">
              <input
                placeholder="Please enter User E-mail"
                name="eMail"
                className="form-control bg-transparent"
                type="email"
                autoComplete="off"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
              />
              {!isEmailValid && <span className="error">Invalid E-mail</span>}
            </div>
          }
          footerHtml={
            <div>
              <button
                className="btn btn-primary mr-3"
                onClick={() => {
                  if (!validateEmail(inviteEmail)) {
                    setIsEmailValid(false);
                    return;
                  }
                  sendInvite(inviteEmail);
                  closeInviteModal();
                }}
              >
                Invite
              </button>
              <button
                className="btn btn-info"
                onClick={() => closeInviteModal()}
              >
                Cancel
              </button>
            </div>
          }
          onHide={() => closeInviteModal()}
        />
      </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        users: state.userManagement.users || [],
        okcrUsers: state.userManagement.okcrUsers || [],
        theme: state.user.theme,
        loading: state.userManagement.loading,
    });
}

const mapDispatchToProps = (dispatch) => ({
    getUsersList: () => dispatch(getUsersList()),
    getOkcrUsersList: () => dispatch(getOkcrUsersList()),
    addUser: () => dispatch(addUser()),
    editUser: (user) => dispatch(editUser(user)),
    deleteUser: (userDetails) => dispatch(deleteUser(userDetails)),
    udpateUserPassword: (userDetails) => dispatch(udpateUserPassword(userDetails)),
    sendInvite: (email) => dispatch(sendInvite(email)),
});

const UserManagementContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagement);

export default UserManagementContainer;
