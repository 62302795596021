import { handleActions } from "redux-actions";

const defaultState = {
    __persist: false,
    okcrList: [],
    okcrAccounts: [],
    sessionId: null,
    error: null,
    loading: false
};

export default handleActions(
    {
        OKCR_LOGIN_REQUEST: (state) => {
            return {
                ...state,
                okcrList: [],
                loading: true,
            };
        },

        OKCR_LOGIN_REQUEST_SUCCESS: (state, payload) => {
            return {
                ...state
            }
        },

        OKCR_LOGIN_REQUEST_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        OKCR_LISTING_REQUEST: (state) => {
            return {
                ...state,
                okcrList: [],
            };
        },

        OKCR_LISTING_SUCCESS: (state, { payload: { okcrList } }) => {
            okcrList = okcrList.map((i) => ({
              ...i,
              dw: i.dw ? new Date(i.dw) : null,
            }));
            return {
                ...state,
                okcrList
            }
        },

        OKCR_LISTING_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        OKCR_ACCOUNTS_REQUEST_SUCCESS: (state, { payload: { okcrAccounts } }) => {
            return {
                ...state,
                okcrAccounts,
                loading: false
            }
        },

        OKCR_DELETE_USER_REQUEST: (state) => {
			return {
				...state
			}
		},

		OKCR_DELETE_USER_SUCCESS: (state, { payload: { userDetails } }) => {
			const okcrAccounts = state.okcrAccounts.filter( user => user.un !== userDetails.username );
			return {
				...state,
				okcrAccounts
			}
		},

		OKCCR_DELETE_USER_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				error: err,
			};
		},

        CLEAR_ALL_DATA: () => {
            return {
                ...defaultState,
            };
        },
    },
    defaultState
);
