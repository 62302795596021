import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import moment from "moment";

import "react-tagsinput/react-tagsinput.css";
import SelectViewNew from "../searchFilter/SelectViewNew";

import { addInstrumentRunsheet } from "../../actions/instrumentAction";

const EditInstrument = ({
  addInstrumentRunsheet,
  data,
  selectedRunsheet,
  onCancel,
  county,
  theme
}) => {
  const [mode, setMode] = useState("add");
  const [iid, setIID] = useState(data.iid ?? "");
  const [it, setIT] = useState(data.it ?? "");
  const [co, setCo] = useState(data.co ?? "");
  const [bn, setBN] = useState(data.bn ?? "");
  const [pn, setPN] = useState(data.pn ?? "");
  const [ide, setID] = useState(data.ide ? moment(data.ide).format("Y-MM-DD") : '');
  const [rd, setRD] = useState(data.rd ? moment(data.rd).format("Y-MM-DD") : '');
  const [ed, setED] = useState(data.ed ? moment(data.ed).format("Y-MM-DD"): '');
  const [qtr, setQTR] = useState(data.qtr ? data.qtr.split(" | ") : []);
  const [ge, setGE] = useState(data.ge ? data.ge.split(" | ") : []);
  const [gr, setGR] = useState(data.gr ? data.gr.split(" | ") : []);
  const [un, setUN] = useState(data.un ?? "");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(data.rid) setMode("edit");
    setIID(data.iid ?? "");
    setIT(data.it ?? "");
    setCo(data.co ?? "");
    setBN(data.bn ?? "");
    setPN(data.pn ?? "");
    setID(data.ide ? moment(data.ide).format("Y-MM-DD") : '');
    setRD(data.rd ? moment(data.rd).format("Y-MM-DD") : '');
    setQTR(data.qtr ? data.qtr.split(" | ") : []);
    setGE(data.ge ? data.ge.split(" | ") : []);
    setGR(data.gr ? data.gr.split(" | ") : []);
    setUN(data.un ?? "");
    setED(data.ed ? moment(data.ed).format("Y-MM-DD"): '');
  }, [data]);

  const onSubmit = () => {
    const formErrors = validate();
    if(Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
      return;
    }
    delete data.s;
    addInstrumentRunsheet({
      ...data,
      rid: selectedRunsheet.rid,
      ide: ide === '' ? null : ide,
      rd: rd === '' ? null : rd,
      iid,
      co,
      it,
      bn,
      pn,
      ed: ed === '' ? null : ed,
      qtr: qtr.join(" | "),
      ge: ge.join(" | "),
      gr: gr.join(" | "),
      un: un,
      idt: 0,
    });
    onCancel();
  };

  const validate = () => {
    const errors = {}
    if(!iid) {
      errors.iid = 'Instrument is required'
    }
    return errors;
  } 

  return (
    <div className={`edit-instrument${theme === 'dark' ? '-dark' : ''}`}>
      <div className="content">
        <label>Instrument</label>
        <div>
        <input
            placeholder="Instrument"
            name="iid"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={mode === "edit"}
            value={iid}
            onChange={(e) => setIID(e.target.value)}
        />
        {errors.iid && (<span className="error">{errors.iid}</span>)}
        </div>
        <label>Instrument Type</label>
        <div>
        <input
            placeholder="Instrument Type"
            name="it"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={mode === "edit"}
            value={it}
            onChange={(e) => setIT(e.target.value)}
        />
        </div>
        <label>County</label>
        {mode !== "edit" && <div>
          <SelectViewNew
            className={`filter county-select ${theme === 'dark' ? 'dark' : ''}`}
            options={county.map((item) => ({ value: item, label: item }))}
            value={{label: co, value: co}}
            placeholder="County"
            isDisabled
            onChange={(e) => setCo(e[0])}
            width={300}
            theme={theme}
          />
        {errors.co && (<span className="error">{errors.co}</span>)}
        </div>}
        {mode === "edit" && <div>
        <input
            placeholder="County"
            name="co"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={true}
            value={co}
        />
        </div>}
        <label>Instrument Date</label>
        <div>
          <input
            type="date"
            value={ide}
            className="form-control bg-transparent date-filter"
            onChange={(e) => setID(e.target.value)}
          />
        </div>
        <label>Recorded Date</label>
        <div>
          <input
            type="date"
            value={rd}
            className="form-control bg-transparent"
            onChange={(e) => setRD(e.target.value)}
          />
        </div>
        <label>Book</label>
        <div>
        <input
            placeholder="Book"
            name="bn"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={mode === "edit"}
            value={bn}
            onChange={(e) => setBN(e.target.value)}
        />
        </div>
        <label>Page</label>
        <div>
        <input
            placeholder="Page"
            name="pn"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={pn}
            onChange={(e) => setPN(e.target.value)}
        />
        </div>
        <label>Quarter</label>
        <TagsInput
        // className="form-control"
          value={qtr}
          onChange={(value) => setQTR(value)}
          inputProps={{ placeholder: "Add a quarter" }}
        />
        <label>Grantor's Name</label>
        <TagsInput
        // className="form-control"
          value={gr}
          onChange={(value) => setGR(value)}
          inputProps={{ placeholder: "Add a grantor" }}
        />
        <label>Grantee's Name</label>
        <TagsInput
          value={ge}
          // className="form-control"
          onChange={(value) => setGE(value)}
          inputProps={{ placeholder: "Add a grantee" }}
        />
        <label>Effective Date</label>
        <div>
          <input
            type="date"
            value={ed}
            className="form-control bg-transparent"
            onChange={(e) => setED(e.target.value)}
          />
        </div>
        <label>User Notes</label>
        <div>
          <textarea
            placeholder="User Notes"
            className="form-control bg-transparent"
            value={un}
            onChange={(e) => setUN(e.target.value)}
          />
        </div>
      </div>
      <div className="buttons">
        <button className="btn btn-primary" onClick={onSubmit}>
          Save
        </button>
        <button className="btn btn-info" onClick={() => onCancel()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editRunsheetInstrument: state.instrument.editRunsheetInstrument,
  selectedRunsheet: state.instrument.selectedRunsheet,
  theme: state.user.theme,
  county: state.advancedSearch.county,
});

const mapDispatchToProps = (dispatch) => ({
  addInstrumentRunsheet: (runsheet) =>
    dispatch(addInstrumentRunsheet(runsheet)),
});

const EditInstrumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInstrument);

export default EditInstrumentContainer;
