/**
 */
import * as types from "./types";

/**
 */
export const login = (email, password) => ({
  type: types.LOGIN_USER_REQUEST,
  payload: { email, password },
});

export const signup = (userDetails, token) => ({
  type: types.SIGNUP_REQUEST,
  payload: { userDetails, token },
});

/**
 */
export const logout = (email) => ({
  type: types.LOGOUT_USER_REQUEST,
  payload: { email },
});

export const changeTheme = (mode) => ({
  type: types.CHANGE_THEME,
  payload: { mode },
});