import axios from "axios";

export const doFetchDataLog = (filters) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/admin/getscraperlog", filters, {
                baseURL: process.env.REACT_APP_API_URL,
            })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export const doFetchDataError = (filters) => {
  return new Promise((resolve, reject) => {
      axios
          .post("/admin/getscrapererror", filters, {
              baseURL: process.env.REACT_APP_API_URL,
          })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
  });
};