/**
 * Redux Store
 */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers";
import RootSaga from "../sagas";
import persistState from "redux-localstorage";
import persistSlicer from "redux-localstorage-slicer";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

let appStore = null;

// configure redux store
export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      persistState(null, {
        slicer: persistSlicer(),
      })
    )
  );

  appStore = store;

  sagaMiddleware.run(RootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export function getStore() {
  return appStore;
}
