import axios from "axios";
import { getStore } from "./store";

class AxiosConfig {
  static init() {
    axios.interceptors.request.use((config) => {
      const token = getStore().getState().user.accessToken;
      config.baseURL = process.env.REACT_APP_API_URL;
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    axios.interceptors.request.use(null, (error) => {
      return Promise.reject(error);
    });
  }
}

export default AxiosConfig;
