import Map, { Source, Layer
  // NavigationControl
} from 'react-map-gl';
import { connect } from 'react-redux';
import './MapgroundNew.scss'
import { useEffect, useState } from 'react';
import ControlPanel from './ControlPanel';
import { MdInfoOutline } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';

const MapgroundNew = ({
  geojson,
  height,
  setMapHeight,
  colorMapping,
  theme,
}) => {
  const [mapKey, setMapKey] = useState(0); // Initial key value
  const [showInfo, setShowInfo] = useState(true);
  const [isExpandMap, setIsExpandMap] = useState(false);
  const [viewport, setViewport] = useState({
    longitude: -102.5,
    latitude: 35.4,
    zoom: 6
  });
  const data = {
    type: 'FeatureCollection',
    features: geojson
  };

  // const colors = {
  //   Conveying_Documents: '#3bb2d0', //8ad4eb
  //   Leases: '#3bb2d0', //bece7d
  //   // Court_Documents: '#223b53', //f2c80f
  //   // Mortgages: '#e55e5e', //de6418
  //   // Stats: '#e55e5e',
  //   Others: '#3bb2d0' //#fbb03b
  // };

  // const circleColor = [
  //   'match',
  //   [ 'get', 'ic' ],
  //   'Conveying_Documents',
  //   colors.Conveying_Documents,
  //   'Leases',
  //   colors.Leases,
  //   // 'Court_Documents',
  //   // colors.Court_Documents,
  //   // 'Mortgages',
  //   // colors.Mortgages,
  //   // 'Stats',
  //   // colors.Stats,
  //   colors.Others
  // ];

  useEffect(() => {
    const coordinates = geojson.map(geo => {
      return geo.geometry.coordinates
    });
    if(coordinates.length) {
      let minLongitude = coordinates[0][0];
      let maxLongitude = coordinates[0][0];
      let minLatitude = coordinates[0][1];
      let maxLatitude = coordinates[0][1];
  
      // Iterate over the remaining coordinates to find the min and max values
      for (let i = 1; i < coordinates.length; i++) {
        const [longitude, latitude] = coordinates[i];
        if (longitude < minLongitude) {
          minLongitude = longitude;
        }
        if (longitude > maxLongitude) {
          maxLongitude = longitude;
        }
        if (latitude < minLatitude) {
          minLatitude = latitude;
        }
        if (latitude > maxLatitude) {
          maxLatitude = latitude;
        }
      }
  
      // Create the bounds array with the southwest and northeast corners
      const bounds = [
        [minLongitude, minLatitude],   // Southwest corner [longitude, latitude]
        [maxLongitude, maxLatitude],   // Northeast corner [longitude, latitude]
      ];
      const calculateCenterAndZoom = (bounds) => {
        const [sw, ne] = bounds;
        const WORLD_WIDTH = 256;
        const PADDING = 40; // Adjust the padding as needed
        const lngDiff = Math.abs(ne[0] - sw[0]);
        const latDiff = Math.abs(ne[1] - sw[1]);
        const center = [(Number(ne[0]) + Number(sw[0])) / 2, (Number(ne[1]) + Number(sw[1])) / 2];
        // const zoomOffset = 2;
        const latZoom = Math.log2((WORLD_WIDTH - PADDING) / latDiff);
        const lngZoom = Math.log2((1800 - PADDING) / lngDiff);
        const zoom = Math.min(latZoom, lngZoom);
        return {
          longitude: center[0],
          latitude: center[1],
          zoom: zoom > 11 ? 11 : zoom,
        };
      };
      const { longitude, latitude, zoom } = calculateCenterAndZoom(bounds);
      setViewport({
        longitude,
        latitude,
        zoom
      });
      setMapKey(mapKey + 1);
    }
    // eslint-disable-next-line
    }, [geojson]);

    useEffect(() => {
      setMapKey(mapKey + 1);
      // eslint-disable-next-line
    }, [height])

  // const handleHeight = (isExpand) => {
  //   console.log(height, isExpand);
  //   if(isExpand) {
  //     setHeight('100%');
  //   } else {
  //     setHeight('0%');
  //   }
  //   setMapKey(mapKey + 1);
  // }
  return (
      <div className ={`map ${theme}`}>
      <Map
      initialViewState={viewport}
      key={mapKey}
      style={{ width: '100%', height}}
      mapStyle="mapbox://styles/syntaxok/cjze4e2rr02l71ds7yshkzq9a?optimize=true"
      mapboxAccessToken="pk.eyJ1Ijoic3ludGF4b2siLCJhIjoiY2p5eTVzajgzMTQ0NTNlcm81aWd4dDNnNiJ9.sDCh9tMKjw--uKfyFkzOTQ"
      >
        <Source type="geojson" data={data}>
        <Layer
          id="marker-layer"
          type="circle"
          paint={{
            'circle-radius': {
              base: 1.75,
              stops: [ [ 8, 2 ], [ 12, 20 ], [ 15, 100 ] ]
            },
            'circle-color': ['get', 'color'],
            'circle-opacity': {
              stops: [ [ 10, 1 ], [ 12, 0.3 ], [13, 0.2], [ 15, 0.1 ] ]
            }
          }}
        />
        </Source>
        {/* <NavigationControl showCompass={false} /> */}
        { Object.keys(colorMapping).length > 0 && showInfo && <ControlPanel colors={colorMapping}/>}
        <FontAwesomeIcon className="btn expand" icon={isExpandMap ? faCompressArrowsAlt : faExpandArrowsAlt } onClick={() => {
          if(isExpandMap) {
            setMapHeight([53, 47]);
          } else {
            setMapHeight([103, 0]);
          }
          setIsExpandMap(!isExpandMap);
        }}/>
      </Map>
      { Object.keys(colorMapping).length ? (
      <div className='info-icon'>
        <MdInfoOutline
              className={`icon`}
              onClick={() => setShowInfo(!showInfo)}
        />
      </div>) : '' }
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(MapgroundNew);