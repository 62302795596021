/**
 * Root Sagas
 */
import { all } from "redux-saga/effects";

// sagas
import userSaga from "./user";
import instrumentSaga from "./instrument";
import userManagementSaga from "./userManagement";
import okcrSaga from "./okcr";
import advancedSearchSaga from "./advancedSearch";
import userLogSaga from "./userLog";
import dailyReportSaga from "./dailyReport";
import dataLog from "./dataLog";

export default function* rootSaga() {
  yield all([userSaga(), instrumentSaga(), userManagementSaga(), okcrSaga(), advancedSearchSaga(), userLogSaga(), dailyReportSaga(), dataLog()]);
}
