/* eslint-disable react/prop-types */
import React from 'react';

const Section = ({ className, section, onChange }) => {
  const onChangeHandler = (e) => {
    const input = e.currentTarget;
    const cursor = input.selectionStart;
    onChange(input.value);
    setTimeout(() => {
      input.selectionStart = cursor;
    }, 1);
  };

  return (
    <div className={className}>
      {className === 'filter' && <div className="filter-title">Section</div>}
      <div className="filter-content">
        <input
          style={{ width: '100px' }}
          type="text"
          className='form-control'
          value={section}
          onChange={onChangeHandler}
          placeholder="Section"
        />
      </div>
    </div>
  );
};

export default Section;
