import React, { createContext, useContext, useState, useMemo } from 'react';

const RowContext = createContext();

export const useRowContext = () => useContext(RowContext);

export const RowProvider = ({ children }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const contextValue = useMemo(() => {
    const onExpandCollapseRow = (rowIndex) => {
      setExpandedRowIndex(rowIndex);
    };
    return {
      expandedRowIndex,
      onExpandCollapseRow
    }
  }, [expandedRowIndex]);

  return (
    <RowContext.Provider value={contextValue}>
      {children}
    </RowContext.Provider>
  );
};
