import axios from "axios";

export const doFetchDailyReport = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/getdailyreport", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};