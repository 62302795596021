/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { setTRSM } from '../../actions/advancedSearchAction';
import SelectView from './SelectView';
import Section from './Section';
import { parseTRSMString, stringifyTRSM } from '../../utils';

const TRSMAdvanced = ({
  data: { township, range, trsm, selectedCounty, countyRelations },
  onClose,
  setTRSM
}) => {
  const [ trsmList, setTRSMList ] = useState([]);
  const [ twp, setTwp ] = useState(township);
  const [ rng, setRng ] = useState(range);
  const [ errors, setErrors ] = useState([]);

  useEffect(
    () => {
      setTRSMList(parseTRSMString(trsm.join(", ")));
    },
    [ trsm ]
  );

  useEffect(
    () => {
      if (selectedCounty.length > 0) {
        const countryData = countyRelations.filter((c) => selectedCounty.includes(c.county));
        setTwp([
          ...new Set(
            countryData
              .reduce((a, i) => [ ...a, ...i.township.split(',') ], [])
              .map((t) => t.trim())
              .sort()
          )
        ]);
        setRng([
          ...new Set(
            countryData
              .reduce((a, i) => [ ...a, ...i.range.split(',') ], [])
              .map((t) => t.trim())
              .sort()
          )
        ]);
      }
    },
    [ selectedCounty, countyRelations ]
  );

  const addLevelHandler = (e) => {
    setTRSMList([ ...trsmList, { township: '', range: '', section: ''} ]);
  };

  const deleteLevelHandler = (index) => {
    setTRSMList(trsmList.filter((_, i) => i !== index));
  };

  const trmChangeHandler = (label, val, index) => {
    setTRSMList([
      ...trsmList.slice(0, index),
      { ...trsmList[index], [label]: val.trim() },
      ...trsmList.slice(index + 1)
    ]);
  };

  const sectionChangeHandler = (val, index) => {
    const section = val
      .replace(/[^0-9,-]/g, '')
      .replace(/,,/g, ',')
      .replace(/--/g, '-')
      .replace(/-,/g, '-')
      .replace(/,-/g, ',')
      .slice(0, 5);

    setTRSMList([
      ...trsmList.slice(0, index),
      { ...trsmList[index], section },
      ...trsmList.slice(index + 1)
    ]);
  };

  const updateTRSM = () => {
    const err = [];

    trsmList.map((t, i) => {
      if (!t.township) {
        err[i] = 'Township is mandatory';
      } else if (!t.range) {
        err[i] = 'Range is mandatory';
      }
      return null;
    });

    if (err.length > 0) {
      setErrors(err);
    } else {
      setTRSM(stringifyTRSM(trsmList).replace(/^[;\s]+|[;\s]+$/g, '').split(", "));
      onClose();
    }
  };

  return (
    <Modal show={true} onHide={onClose} animation={false} dialogClassName="TRSM-advanced">
      <Modal.Header>
        <Modal.Title>Advance TRS Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <Button variant="btn btn-primary" onClick={addLevelHandler}>
            Add
          </Button>
        </div>
        <div className="advance-edit">
          {trsmList.map((t, index) => (
            <div key={index} className={(errors[index] ? 'error' : '') + ' advance-edit-content'}>
              <SelectView
                width={100}
                placeholder="Township"
                options={twp.map((item) => ({ value: item, label: item }))}
                values={t.township ? [ { value: t.township, label: t.township } ] : []}
                onChange={(val) => trmChangeHandler('township', val[0], index)}
                searchable
              />
              <SelectView
                width={100}
                placeholder="Range"
                className="ml-2"
                options={rng.map((item) => ({ value: item, label: item }))}
                values={t.range ? [ { value: t.range, label: t.range } ] : []}
                onChange={(val) => trmChangeHandler('range', val[0], index)}
                searchable
              />
              <Section
                className="ml-2"
                section={t.section}
                onChange={(val) => sectionChangeHandler(val, index)}
              />
              <div
                className="ml-2 pl-1"
                style={{ fontSize: '20px' }}
                onClick={() => deleteLevelHandler(index)}
              >
                <FontAwesomeIcon title="Remove" icon={faTimesCircle} />
              </div>
              {errors[index] && <div className="error">{errors[index]}</div>}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-primary" onClick={updateTRSM}>
          OK
        </Button>
        <Button variant="btn btn-info" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.advancedSearch
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTRSM: (trsm) => {
      dispatch(setTRSM(trsm));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TRSMAdvanced);
