import { combineReducers } from "redux";
import user from "./user";
import instrument from "./instrument";
import userManagement from "./userManagement";
import okcr from "./okcr";
import advancedSearch from "./advancedSearch";
import userLog from "./userLog";
import dailyReport from "./dailyReport";
import dataLog from "./dataLog";

const reducers = combineReducers({
  instrument,
  user,
  userManagement,
  okcr,
  advancedSearch,
  userLog,
  dailyReport,
  dataLog,
});

export default reducers;
