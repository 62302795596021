import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import SelectViewNew from "../searchFilter/SelectViewNew"

import {
    createRunsheet,
} from "../../actions/instrumentAction";

import "react-tagsinput/react-tagsinput.css";
import "./createRunsheet.scss"
export const CreateRunsheet = ({
    show,
    cancel,
    createRunsheet,
    setTab,
    selectedRunsheet,
    trs: section,
    mode,
    theme,
    county
}) => {
    selectedRunsheet = mode === "edit" ?  selectedRunsheet || {} : {};
    const defaultValues = {
        rid: selectedRunsheet.rid,
        co: selectedRunsheet.co || '',
        un: selectedRunsheet.un || '',
        fd: selectedRunsheet.fd ? moment(selectedRunsheet.fd).format("Y-MM-DD") : '',
        td: selectedRunsheet.td ? moment(selectedRunsheet.td).format("Y-MM-DD") : '',
        pn: selectedRunsheet.pn || '',
        cn: selectedRunsheet.cn || '',
        mo: selectedRunsheet.mo || '',
        li: selectedRunsheet.li || '',
        lbpr: selectedRunsheet.lbpr || '',
        ld: selectedRunsheet.ld || '',
    };
    const [errors, setErrors] = useState({});
    const [rsn, setRsn] = useState(selectedRunsheet.rsn ?? '');
    const [co, setCo] = useState(selectedRunsheet.co ?? '');
    const [trs, setTrs] = useState(section ?? '');
    const [runsheet, setRunsheet] = useState(defaultValues);

    useEffect(() => {
        if(mode === "create") {
            setTrs(section ?? '');
            setRsn(section ? section + moment().format("_YMMDDhhmm") : '');
        }
    }, [section, mode]);

    const inputsHandler = (e) => {
        setRunsheet({ ...runsheet, [e.target.name]: e.target.value });
        if (e.target.name === 'trs') setTrs(e.target.value);
        if (e.target.name === 'rsn') setRsn(e.target.value);
        const formErrors = validate();
        if (!Object.keys(formErrors).includes(e.target.name)) {
            setErrors({ ...errors, [e.target.name]: null });
        }
    }

    const validateTRS = (trs) => {
        try {
            let _trs = trs;
            let t = _trs.match(/\d{1,2}[nsNS]{1}/)[0];
            _trs = _trs.replace(t, "");
            t = t.toUpperCase().padStart(3, "0");
            let r = _trs.match(/\d{1,2}[ewEW]{1}/)[0];
            _trs = _trs.replace(r, "");
            r = r.toUpperCase().padStart(3, "0");
            const s = _trs.match(/\d{1,2}/)[0].padStart(2, "0");
            _trs = t + "-" + r + "-" + s;
            return trs;
        } catch (error) {
            return null;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            const data = {
                ...runsheet,
                rsn,
                trs,
                co,
                fd: runsheet.fd === '' ? null : runsheet.fd,
                td: runsheet.td === '' ? null : runsheet.td
            };
            createRunsheet(data);
            setTab('run');
            close();
        } else {
            setErrors(formErrors);
        }
    }

    const validate = () => {
        const errors = {};
        if (!rsn) {
            errors.rsn = 'Runsheet name is required';
        }
        const _trs = validateTRS(trs);
        if (!_trs) {
            errors.trs = 'Invalid TRS';
        }
        return errors;
    }

    const close = () => {
        setErrors({});
        setTrs(section ?? '');
        setRsn(section ? section + moment().format("_YMMDDhhmm") : '');
        setRunsheet(defaultValues);
        cancel();
    }

    return (
        <Modal
            show={show}
            onHide={cancel}
        >
            <div className={`create-runsheet${theme === 'dark' ? '-dark' : ''}`}>
            <Modal.Header>
                <Modal.Title>Create Runsheet</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body className="fixed-height">
                    <div className="m-2">
                        <label>Runsheet name</label>
                        <div className="mb-2">
                            <input
                                placeholder="Runsheet name"
                                name="rsn"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={rsn}
                                onChange={inputsHandler}
                            />
                            {errors.rsn && (<span className="error">{errors.rsn}</span>)}
                        </div>

                        <label>TRS</label>
                        <div className="mb-2">
                            <input
                                placeholder="TRS"
                                name="trs"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={trs}
                                onChange={inputsHandler}
                            />
                            {errors.trs && (<span className="error">{errors.trs}</span>)}
                        </div>

                        <label>County</label>
                        <div className="mb-2">
                            {/* <input
                                placeholder="County"
                                name="co"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.co}
                                onChange={inputsHandler}
                            /> */}
                            <SelectViewNew
                                className="filter county-select"
                                options={county.map((item) => ({ value: item, label: item }))}
                                values={{label: co, value: co}}
                                placeholder="County"
                                onChange={(e) => setCo(e[0])}
                                width={446}
                                theme={theme}
                            />
                        </div>

                        <label>From Date</label>
                        <div className="mb-2">
                            <input
                                type="date"
                                name="fd"
                                className="form-control bg-transparent"
                                value={runsheet.fd}
                                onChange={inputsHandler}
                            />
                        </div>
                        <label>To Date</label>
                        <div className="mb-2">
                            <input
                                type="date"
                                name="td"
                                className="form-control bg-transparent"
                                value={runsheet.td}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Project Name</label>
                        <div className="mb-2">
                            <input
                                placeholder="Project Name"
                                name="pn"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.pn}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Client Name</label>
                        <div className="mb-2">
                            <input
                                placeholder="Client Name"
                                name="cn"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.cn}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Mineral Owner</label>
                        <div className="mb-2">
                            <input
                                placeholder="Mineral Owner"
                                name="mo"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.mo}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Limitation</label>
                        <div className="mb-2">
                            <input
                                placeholder="Limitation"
                                name="li"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.li}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Last Book Page Reviewed</label>
                        <div className="mb-2">
                            <input
                                placeholder="Last Book Page Reviewed"
                                name="lbpr"
                                className="form-control bg-transparent"
                                type="text"
                                autoComplete="off"
                                value={runsheet.lbpr}
                                onChange={inputsHandler}
                            />
                        </div>

                        <label>Legal Description</label>
                        <div className="mb-2">
                            <textarea
                                placeholder="Legal Description"
                                name="ld"
                                className="form-control bg-transparent"
                                value={runsheet.ld}
                                onChange={inputsHandler}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className="btn btn-primary"
                    >
                        { mode === "edit" ? "Update" : "Create" } 
                    </button>
                    <button type="button" className="btn btn-info" onClick={close}>
                        Close
                    </button>
                </Modal.Footer>
            </form>
            </div>
        </Modal>
    )

};

const mapStateToProps = (state) => ({
    selectedRunsheet: state.instrument.selectedRunsheet,
    trs: state.instrument.trs,
    username: state.user.username,
    savedSheets: state.instrument.savedSheets,
    theme: state.user.theme,
    county: state.advancedSearch.county,
});

const mapDispatchToProps = (dispatch) => ({
    createRunsheet: (runsheet) => dispatch(createRunsheet(runsheet)),
});

const CreateRunsheetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateRunsheet);

export default CreateRunsheetContainer;

