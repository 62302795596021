import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/dataLog";

export function* getDataLog({ payload: { filters } }) {
  try {
    const logs = yield call(api.doFetchDataLog, filters);
    yield put({
      type: types.FETCH_DATA_LOG_SUCCESS,
      payload: logs,
    });
  } catch (err) {
    yield put({
      type: types.FETCH_DATA_LOG_FAILURE,
      payload: { err },
    });
  }
}

export function* getDataError({ payload: { filters } }) {
  try {
    const logs = yield call(api.doFetchDataError, filters);
    yield put({
      type: types.FETCH_DATA_ERROR_SUCCESS,
      payload: logs,
    });
  } catch (err) {
    yield put({
      type: types.FETCH_DATA_ERROR_FAILURE,
      payload: { err },
    });
  }
}

export default function* dataLogSaga() {
  yield takeEvery(types.FETCH_DATA_LOG_REQUEST, getDataLog);
  yield takeEvery(types.FETCH_DATA_ERROR_REQUEST, getDataError);
}
