import { handleActions } from "redux-actions";

/**
 * data redux store.
 */
const defaultState = {
  // __persist: ["user"],
  loading: false, // indicated the progress when search api call
  loadingPdf: false,
  user: null, //login user info
  trs: "",
  instruments: [], // list of available instruments
  pdfData: null,
  instrumentPDFs: {},
  result: -1,
  error: "",
  savedSheets: [],
  selectedRunsheet: null,
  runsheetInstruments: [],
};

export default handleActions(
  {
    RESET_TRS: (state) => {
      return {
        ...state,
        trs: ""
      }
    },
    GET_INSTRUMENTS_REQUEST: (state, { payload: { trs } }) => {
      return {
        ...state,
        trs,
        selectedRunsheet: null,
        instruments: [],
        runsheetInstruments: [],
        loading: true,
        loadingPdf: true,
      };
    },

    GET_INSTRUMENTS_SUCCESS: (state, { payload: { instruments } }) => {
      const runsheetInstruments = state.runsheetInstruments.map((r) => r.iid);
      instruments = instruments.map((i, index) => ({
        ...i,
        index,
        s: runsheetInstruments.includes(i.iid),
        ide: i.ide ? new Date(i.ide) : null,
        rd: i.rd ? new Date(i.rd) : null,
        expand: false,
      }));
      return {
        ...state,
        instruments,
        instrumentPDFs: {},
        loading: false,
        error: "",
      };
    },

    GET_INSTRUMENTS_FAILURE: (state, { payload: error }) => {
      return {
        ...state,
        loading: false,
        loadingPdf: false,
        trs: "",
        error,
      };
    },

    GET_TRS_DOCUMENT_SUCCESS: (state, { payload: { trs, pdfData } }) => {
      return {
        ...state,
        pdfData: window.URL.createObjectURL(
          new Blob([pdfData], { type: "application/pdf" })
        ),
        loadingPdf: false,
        error: "",
      };
    },

    GET_TRS_DOCUMENT_FAILURE: (state, { payload: { trs, error } }) => {
      return {
        ...state,
        loadingPdf: false,
        error,
      };
    },

    GET_INST_DOCUMENT_REQUEST: (state, { payload: { instrument } }) => {
      return {
        ...state,
        instrumentPDFs: {
          ...state.instrumentPDFs,
          [instrument.iid]: {},
        },
      };
    },

    GET_INST_DOCUMENT_SUCCESS: (
      state,
      { payload: { instrument, pdfData } }
    ) => {
      let instrumentPDFs = state.instrumentPDFs;
      if (instrumentPDFs.hasOwnProperty(instrument.iid))
        instrumentPDFs = {
          ...instrumentPDFs,
          [instrument.iid]: {
            pdf: window.URL.createObjectURL(
              new Blob([pdfData], { type: "application/pdf" })
            ),
            instrument: instrument,
            isEditing: false
          }
        };
      return {
        ...state,
        instrumentPDFs,
        error: "",
      };
    },

    REMOVE_TAB: (state, { payload: { instrumentId } }) => {
      const instrumentPDFs = state.instrumentPDFs;
      delete instrumentPDFs[instrumentId];
      return {
        ...state,
        instrumentPDFs: {
          ...instrumentPDFs,
        },
      };
    },

    GET_RUNSHEET_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    GET_RUNSHEET_SUCCESS: (
      state,
      { payload: { runsheet, runsheetInstruments } }
    ) => {
      runsheetInstruments = runsheetInstruments.map((i, index) => ({
        ...i,
        index,
        ide: i.ide ? new Date(i.ide) : null,
        rd: i.rd ? new Date(i.rd) : null,
        ed: i.ed ? new Date(i.ed) : null,
        expand: false,
      }));
      runsheet = {
        ...runsheet,
        cd: new Date(runsheet.cd),
        ud: new Date(runsheet.ud),
        fd: runsheet.fd ? new Date(runsheet.fd) : null,
        td: runsheet.td ? new Date(runsheet.td) : null,
      }
      return {
        ...state,
        selectedRunsheet: runsheet,
        runsheetInstruments,
        // loading: false,
        error: null,
      };
    },

    GET_RUNSHEET_FAILURE: (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
        error: err,
      };
    },

    CREATE_RUNSHEET_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    CREATE_RUNSHEET_SUCCESS: (state, { payload: { runsheet } }) => {
      const instruments = state.instruments.map((i) => ({
        ...i,
        s: false,
      }));
      runsheet = {
        ...runsheet,
        cd: new Date(runsheet.cd),
        ud: new Date(runsheet.ud),
        fd: runsheet.fd ? new Date(runsheet.fd) : null,
        td: runsheet.td ? new Date(runsheet.td) : null,
      }
      return {
        ...state,
        instruments,
        savedSheets: [...state.savedSheets, runsheet],
        selectedRunsheet: runsheet,
        runsheetInstruments: [],
        loading: false,
        error: null,
      };
    },

    EDIT_RUNSHEET_SUCCESS: (state, { payload: { runsheet } }) => {
      runsheet = {
        ...runsheet,
        cd: new Date(runsheet.cd),
        ud: new Date(runsheet.ud),
        fd: runsheet.fd ? new Date(runsheet.fd) : null,
        td: runsheet.td ? new Date(runsheet.td) : null,
      }
      return {
        ...state,
        savedSheets: [...state.savedSheets.filter(r => r.rid !== runsheet.rid), runsheet],
        selectedRunsheet: runsheet,
        loading: false,
        error: null,
      };
    },

    DELETE_RUNSHEET_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    DELETE_RUNSHEET_SUCCESS: (state, { payload: { runsheet } }) => {
      const savedSheets = state.savedSheets.filter( sheet => sheet.rid !== runsheet.rid);
      return {
        ...state,
        savedSheets,
        loading: false,
        error: null,
      };
    },

    DELETE_RUNSHEET_FAILURE: (state, { payload: error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },

    CREATE_RUNSHEET_FAILURE: (state, { payload: error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },

    ADD_INSTRUMENT_RUNSHEET_REQUEST: (state) => {
      return {
        ...state,
        error: null,
      };
    },

    ADD_INSTRUMENT_RUNSHEET_SUCCESS: (state, { payload: { runsheet } }) => {
      let runsheetInstruments = state.runsheetInstruments;
      if (runsheet.idt)
        runsheetInstruments = runsheetInstruments.filter(
          (r) => r.iid !== runsheet.iid
        );
      else {
        const ri = runsheetInstruments
          .map((rs, i) => ({
            i,
            ...rs,
          }))
          .find((rs) => rs.iid === runsheet.iid);
        runsheet = {
          ...runsheet,
          ide: runsheet.ide ? new Date(runsheet.ide) : null,
          rd: runsheet.rd ? new Date(runsheet.rd) : null,
          ed: runsheet.ed ? new Date(runsheet.ed) : null,
          expand: false,
        };
        if (ri) runsheetInstruments[ri.i] = runsheet;
        else runsheetInstruments.push({ ...runsheet, s: true });
      }
      const ri = runsheetInstruments.map((r) => r.iid);
      const instruments = state.instruments.map((i) => ({
        ...i,
        s: ri.includes(i.iid),
      }));
      let instrumentPDFs = state.instrumentPDFs;
      if (instrumentPDFs.hasOwnProperty(runsheet.iid))
        instrumentPDFs = {
          ...instrumentPDFs,
          [runsheet.iid]: {
            ...instrumentPDFs[runsheet.iid],
            instrument: runsheet
          }
      };
      return {
        ...state,
        instruments,
        runsheetInstruments,
        instrumentPDFs,
        loading: false,
        error: null,
      };
    },

    ADD_INSTRUMENT_RUNSHEET_FAILURE: (state, { payload: err }) => {
      return {
        ...state,
        loading: false,
        error: err,
      };
    },

    ADD_RUNSHEET_INSTRUMENT: (
      state
    ) => {
      return {
        ...state,
      };
    },


  DOWNLOAD_INST_DOCUMENT_SUCCESS: (
      state,
      { payload: { instrument, pdfData } }
  ) => {
      let instrumentPDFs = state.instrumentPDFs;
      if (instrumentPDFs.hasOwnProperty(instrument.iid)) {
          instrumentPDFs = {
              ...instrumentPDFs,
              [instrument.iid]: {
                  pdf: window.URL.createObjectURL(
                      new Blob([pdfData], { type: "application/pdf" })
                  ),
                  instrument: instrument,
                  isEditing: false
              }
          };
      }
      let instruments = state.instruments;
      instruments = instruments.map(inst => {
          if (inst.iid === instrument.iid) {
              inst.hd = true;
          }
          return inst;
      });
      return {
          ...state,
          instrumentPDFs,
          instruments,
          error: "",
      };
  },

    // GET_INST_DOCUMENT_FAILURE: (state, { payload: { instrument, error } }) => {
    //   return {
    //     ...state,
    //     loadingPdf: [...state.loadingPdf.filter((l) => l !== instrument)],
    //     error,
    //   };
    // },

    HANDLE_PDF_EDIT: (state, { payload: { id, isOpen } }) => {
      const instrumentPDFs = {
        ...state.instrumentPDFs,
        [id]: {
          ...state.instrumentPDFs[id],
          isEditing: isOpen
        }
      };
      return {
        ...state,
        instrumentPDFs
      }
    },

    CLEAR_ALL_DATA: () => {
      return {
        ...defaultState,
      };
    },

    GET_SAVED_SHEETS_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    GET_SAVED_SHEETS_SUCCESS: (state, { payload: { savedSheets } }) => {
      savedSheets = savedSheets.map((i) => ({
        ...i,
        cd: new Date(i.cd),
        ud: new Date(i.ud),
        fd: i.fd ? new Date(i.fd) : null,
        td: i.td ? new Date(i.td) : null,
      }));
      return {
        ...state,
        savedSheets,
        loading: false,
        error: "",
      };
    },

    GET_SAVED_SHEETS_FAILURE: (state, { payload: error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },

    SET_INSTRUMENT_ROW_HEIGHT_AND_MODE: (state, { payload: { index, height, isExpand, isRunsheet } }) => {
      let runsheetInstruments = state.runsheetInstruments;
      let instruments = state.instruments;
      if(isRunsheet) {
        runsheetInstruments = runsheetInstruments.map( instrument => {
          if(instrument.index === index) {
            instrument.height = height;
            instrument.expand = isExpand;
          }
          return instrument;
        });
      } else {
        instruments = instruments.map( instrument => {
          if(instrument.index === index) {
            instrument.height = height;
            instrument.expand = isExpand;
          }
          return instrument;
        });
      }
      return {
        ...state,
        instruments,
        runsheetInstruments
      }
    }
  },
  defaultState
);
