/**
 * User Log Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/dailyReport";

export function* getDailyReport() {
  try {
    const data = yield call(api.doFetchDailyReport);
    if (data) {
      yield put({
        type: types.FETCH_DAILY_REPORT_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: types.FETCH_DAILY_REPORT_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_DAILY_REPORT_FAILURE,
      payload: { err },
    });
  }
}

// User Log Saga
export default function* dailyReportSaga() {
  yield all([
    takeEvery(types.FETCH_DAILY_REPORT_REQUEST, getDailyReport),
  ]);
}
