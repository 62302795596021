import axios from "axios";

// get instruments
export const doGetInstruments = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get trs document
export const doGetTRSDocument = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/sectiondocument", params, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get trs document
export const doGetInstrumentDocument = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/instrumentdocument", params, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetSavedSheets = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/search/searchrunbook",
        {},
        {
          // temporary
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get runsheet
export const doGetRunsheet = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/searchrunbookdata", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// create runsheet
export const doCreateRunsheet = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/addrunsheet", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// udpate runsheet
export const doUpdateRunsheet = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/updaterunsheet", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// create runsheets
export const doAddInstrumentRunsheet = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/addrunsheetdata", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetUsersList = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/admin/getuser",
        {},
        {
          // temporary
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetOkcrUsersList = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "admin/getokcruser",
        {},
        {
          // temporary
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doUpdateUser = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/admin/updateuser", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doAddUser = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/admin/adduser", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doResetPassword = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/admin/resetpassword", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doInviteUser = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/invite", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doDeleteUser = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/admin/deleteuser", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doDeleteRunsheet = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document/deleterunsheet", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doOkcrLogin = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("cg/startagent", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetProgress = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("cg/getprogress", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetOkcrList = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "cg/getdocsmain",
        {},
        {
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetOkcrAccounts = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "cg/okcraccount",
        {},
        {
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doDeleteOkcrUser = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("cg/deleteokcraccount", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const handleUpdatePassword = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/user/changepassword",
        payload,
        {
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error); // Ensure errors are properly rejected
      });
  });
};


export const handleUpdateUserInfo = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/user/updateuser",
        payload,
        {
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error); 
      });
  });
};