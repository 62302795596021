import { connect } from "react-redux";
import { CgChevronDownO, CgChevronUpO } from "react-icons/cg";
import { setInstrumentRowHeightAndMode } from "../../../actions/instrumentAction";
import { setRowHeightAndMode } from "../../../actions/advancedSearchAction";
import { setReportRowHeightAndMode } from "../../../actions/dailyReport";
import { useRowContext } from "../../../contexts/rowContext";
import { useEffect } from "react";
const ShowMoreButtonNew = ({
  colDef: {
    cellRendererParams: {
      field,
      isRunsheet,
      isAdvanced,
      strLength = 34,
      isHyperlink,
      callback,
      page = '',
      tab,
    },
  },
  data,
  setInstrumentRowHeightAndMode,
  setRowHeightAndMode,
  setReportRowHeightAndMode,
}) => {
  // const maxStringLength = 34;
  const { onExpandCollapseRow } = useRowContext();
  const columnArray = data[field] ? data[field].split("|") : [];
  const changeMode = () => {
    let height = 50;
    if (!data.expand) {
      height = calculateHeight();
    }
    if (isAdvanced) {
      setRowHeightAndMode(data.index, height, !data.expand);
    } else {
      setInstrumentRowHeightAndMode(data.index, height, !data.expand, isRunsheet);
    }
    if(data.expand) {
      onExpandCollapseRow(data.index);
    }
    if(page === 'dailyReport') {
      setReportRowHeightAndMode(data.rowId, tab, height, !data.expand)
    }
  };

  useEffect(() => {
    return () => {
      onExpandCollapseRow(null);
    };
  }, [onExpandCollapseRow]);

  const calculateHeight = () => {
    const resizableFields = ["qtr", "ge", "gr", "trs", "fn", "co", "gmd", "obd"];
    const resizableFieldsHeights = resizableFields.map((field) => {
    const arr = data[field] ? data[field].split("|") : [];
    let extraLines = 0;
    if(strLength) {
      arr.forEach(word => {
        if (word.length > strLength) {
          extraLines += Math.ceil(word.length/(strLength + 5));
        }
      });
    }
      return (arr.length * 30) + (extraLines * 15);
    });
    return Math.max(...resizableFieldsHeights) + 50;
  }

  const createHyperlink = (string) => {
    return isHyperlink && data.is_trs ? (
      <button className="link-button" onClick={() => callback(string)}>
        {string}
      </button>
    ) : string;
  };

  const fetchSingleValueContent = () => {
    let content;
    if (data.expand) {
        content = (
          <>
            <span>{createHyperlink(columnArray[0])}</span>
            <span className={`icon ${data.expand ? "btn-up" : "btn-down"}`}>
                <CgChevronUpO className="btn-icon" onClick={changeMode} />
            </span>
          </>
        );
      } else {
        content = (
          <>
            <span>{createHyperlink(columnArray[0].substring(0, strLength))}</span>
            <span className={`icon ${data.expand ? "btn-up" : "btn-down"}`}>
                <CgChevronDownO className="btn-icon" onClick={changeMode} />
            </span>
          </>
        );
    }
    return content;
  }

  let content = columnArray[0] ? createHyperlink(columnArray[0]) : "";

  if (columnArray.length > 1) {
    if(data.expand) {
        content = (
        <span>
            <ul className="no-bullets no-padding">
            {columnArray.map((col, i) => (
                <li className="reduce-margin" key={i}>
                {createHyperlink(col)}
                </li>
            ))}
            </ul>
            <span className={`icon ${data.expand ? "btn-up" : "btn-down"}`}>
                <CgChevronUpO className="btn-icon" onClick={changeMode} />
            </span>
        </span>
        );
    } else {
        content = fetchSingleValueContent();
    }
    // later
  } else if (columnArray.length === 1) {
    if (columnArray[0].length > strLength) {
      content = fetchSingleValueContent();
    }
  }

  return (
    <div className="more-button">
      {content}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setInstrumentRowHeightAndMode: (iid, height, isExpand, isRunsheet) =>
    dispatch(setInstrumentRowHeightAndMode(iid, height, isExpand, isRunsheet)),
  setRowHeightAndMode: (iid, height, isExpand) =>
    dispatch(setRowHeightAndMode(iid, height, isExpand)),
  setReportRowHeightAndMode: (id, tab, height, isExpand) =>
    dispatch(setReportRowHeightAndMode(id, tab, height, isExpand)),
});

const ShowMoreButtonNewContainer = connect(
  null,
  mapDispatchToProps
)(ShowMoreButtonNew);

export default ShowMoreButtonNewContainer;
