/**
 * OKCR Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as types from "../actions/types";
import * as api from "../apis/instrument";

export function* okcrLogin({ payload: { loginDetails } }) {
  try {
    const resData = yield call(api.doOkcrLogin, loginDetails);
    if (resData.success) {
      yield put({
        type: types.OKCR_GET_PROGRESS_REQUEST,
        payload: loginDetails,
      });
    } else {
      yield put({
        type: types.OKCR_LOGIN_REQUEST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.OKCR_LOGIN_REQUEST_FAILURE,
      payload: { err },
    });
  }
}

export function* okcrGetProgress({ payload }) {
  for (let i = 0; i <= 10; i++) {
    try {
      const res = yield call(api.doGetProgress, payload);
      if (res.success) {
        yield put({
          type: types.OKCR_LISTING_REQUEST,
          payload,
        });
        toast.success("OKCR integration successful");
        return;
      } else {
        if (i === 10) {
          toast.error(`OKCR Integration failed`);
        } else {
          yield call(delay, 1500);
        }
      }
    } catch (err) {
      yield put({
        type: types.OKCR_LISTING_FAILURE,
        payload: { err },
      });
    }
  }
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function* okcrListing() {
  try {
    const res = yield call(api.doGetOkcrList);
    if (res) {
      yield put({
        type: types.OKCR_LISTING_SUCCESS,
        payload: { okcrList: res },
      });
      yield put({
        type: types.OKCR_ACCOUNTS_REQUEST,
        payload: {}
      });
    } else {
      yield put({
        type: types.OKCR_LISTING_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.OKCR_LISTING_FAILURE,
      payload: { err },
    });
  }
}

export function* okcrAccountListing() {
  try {
    const res = yield call(api.doGetOkcrAccounts);
    if (res) {
      yield put({
        type: types.OKCR_ACCOUNTS_REQUEST_SUCCESS,
        payload: { okcrAccounts: res },
      });
    } else {
      yield put({
        type: types.OKCR_LISTING_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.OKCR_LISTING_FAILURE,
      payload: { err },
    });
  }
}

export function* okcrDeleteUserAccount({payload: { userDetails }}) {
  try {
    const resData = yield call(api.doDeleteOkcrUser, userDetails);
    if (resData[0]) {
      yield put({
        type: types.OKCR_DELETE_USER_SUCCESS,
        payload: { userDetails },
      });
      toast.success("User Account deleted successfully");
    }
  } catch (err) {
    yield put({
      type: types.OKCR_DELETE_USER_FAILURE,
      payload: { err },
    });
  }
}


// OKCR Saga
export default function* okcrSaga() {
  yield all([
    takeEvery(types.OKCR_LOGIN_REQUEST, okcrLogin),
    takeEvery(types.OKCR_LISTING_REQUEST, okcrListing),
    takeEvery(types.OKCR_GET_PROGRESS_REQUEST, okcrGetProgress),
    takeEvery(types.OKCR_ACCOUNTS_REQUEST, okcrAccountListing),
    takeEvery(types.OKCR_DELETE_USER_REQUEST, okcrDeleteUserAccount)
  ]);
}
