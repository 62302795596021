/**
 * Auth Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/instrument";
import * as instrumentApis from "../apis/advancedSearch";

// Get Instruments
export function* getInstrumentsSaga({ payload: { trs } }) {
  try {
    const resData = yield call(api.doGetInstruments, { trs });
    if (resData[0]?.error) {
      yield put({
        type: types.GET_INSTRUMENTS_FAILURE,
        payload: { err: { message: resData[0].error } },
      });
    } else {
      yield put({
        type: types.TRIGGER_LOCAL_GRID_FILTER
      });
      yield put({
        type: types.GET_INSTRUMENTS_SUCCESS,
        payload: { instruments: resData },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_INSTRUMENTS_FAILURE,
      payload: { err },
    });
  }
}

// Get TRS document
export function* getTRSDocumentSaga({ payload: { trs } }) {
  try {
    const pdfData = yield call(api.doGetTRSDocument, { trs });
    if (pdfData) {
      yield put({
        type: types.GET_TRS_DOCUMENT_SUCCESS,
        payload: { trs, pdfData },
      });
    } else {
      yield put({
        type: types.GET_TRS_DOCUMENT_FAILURE,
        payload: { trs, err: pdfData },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_TRS_DOCUMENT_FAILURE,
      payload: { err },
    });
  }
}

// Get Instrument document
export function* getInstrumentDocumentSaga({ payload: { instrument } }) {
  try {
    const req = { county: instrument.co };
    let apiMethod;
    if(instrument.hd) {
      req['instrument'] = instrument.iid;
      apiMethod = api.doGetInstrumentDocument;
    } else {
      req['instrument_id'] = instrument.iid;
      apiMethod = instrumentApis.doGetInstrumentDocument;
    }
    const pdfData = yield call(apiMethod, req);
    if (pdfData) {
      yield put({
        type: instrument.hd ? types.GET_INST_DOCUMENT_SUCCESS : types.DOWNLOAD_INST_DOCUMENT_SUCCESS,
        payload: { instrument, pdfData },
      });
    } else {
      yield put({
        type: types.GET_INST_DOCUMENT_FAILURE,
        payload: { instrument, err: pdfData },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_INST_DOCUMENT_FAILURE,
      payload: { err },
    });
  }
}

export function* getSavedSheets() {
  try {
    const savedSheets = yield call(api.doGetSavedSheets);
    if (savedSheets) {
      yield put({
        type: types.GET_SAVED_SHEETS_SUCCESS,
        payload: { savedSheets },
      });
    } else {
      yield put({
        type: types.GET_SAVED_SHEETS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_SAVED_SHEETS_FAILURE,
      payload: { err },
    });
  }
}

// Get Runsheet
export function* getRunsheetSaga({ payload: { runsheet } }) {
  try {
    const resData = yield call(api.doGetRunsheet, { rid: runsheet.rid });
    if (resData[0] && resData[0].error) {
      yield put({
        type: types.GET_RUNSHEET_FAILURE,
        payload: { err: { message: resData[0].error } },
      });
    } else {
      yield put({
        type: types.GET_RUNSHEET_SUCCESS,
        payload: { runsheet: runsheet, runsheetInstruments: resData },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_RUNSHEET_FAILURE,
      payload: { err },
    });
  }
}

// Create Runsheet
export function* createRunsheetSaga({ payload: { runsheet } }) {
  try {
    const resData = yield call(runsheet.rid ? api.doUpdateRunsheet : api.doCreateRunsheet, runsheet);
    if (resData[0] && resData[0].error) {
      yield put({
        type: types.CREATE_RUNSHEET_FAILURE,
        payload: { err: { message: resData[0].error } },
      });
    } else {
      if(runsheet.rid) {
        yield put({
          type: types.EDIT_RUNSHEET_SUCCESS,
          payload: { runsheet: resData[0] },
        });
      } else {
        yield put({
          type: types.CREATE_RUNSHEET_SUCCESS,
          payload: { runsheet: resData[0] },
        });
        yield put({
          type: types.GET_RUNSHEET_REQUEST,
          payload: { runsheet: resData[0] }
        });
        yield put({
          type: types.GET_INSTRUMENTS_REQUEST,
          payload: { trs: resData[0].trs }
        });
      }
    }
  } catch (err) {
    yield put({
      type: types.CREATE_RUNSHEET_FAILURE,
      payload: { err },
    });
  }
}

// Add instrument to runsheet
export function* addInstrumentRunsheetSaga({ payload: { runsheet } }) {
  try {
    const resData = yield call(api.doAddInstrumentRunsheet, runsheet);
    if (resData[0] && resData[0].error) {
      yield put({
        type: types.ADD_INSTRUMENT_RUNSHEET_FAILURE,
        payload: { err: { message: resData[0].error } },
      });
    } else {
      yield put({
        type: types.ADD_INSTRUMENT_RUNSHEET_SUCCESS,
        payload: { runsheet: {...resData[0], hd: runsheet.hd} },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADD_INSTRUMENT_RUNSHEET_FAILURE,
      payload: { err },
    });
  }
}

// Add instrument to runsheet
export function* deleteRunsheetSaga({ payload: { runsheet } }) {
  try {
    const resData = yield call(api.doDeleteRunsheet, runsheet);
    if (resData[0]) {
      yield put({
        type: types.DELETE_RUNSHEET_SUCCESS,
        payload: { runsheet: runsheet },
      });
    } else {
      yield put({
        type: types.DELETE_RUNSHEET_FAILURE,
        payload: { err: { message: resData[0].error } },
      });
    }
  } catch (err) {
    yield put({
      type: types.DELETE_RUNSHEET_FAILURE,
      payload: { err },
    });
  }
}

// Quiz Saga
export default function* quizSaga() {
  yield all([
    takeEvery(types.GET_INSTRUMENTS_REQUEST, getInstrumentsSaga),
    takeEvery(types.GET_TRS_DOCUMENT_REQUEST, getTRSDocumentSaga),
    takeEvery(types.GET_INST_DOCUMENT_REQUEST, getInstrumentDocumentSaga),
    takeEvery(types.GET_SAVED_SHEETS_REQUEST, getSavedSheets),
    takeEvery(types.CREATE_RUNSHEET_REQUEST, createRunsheetSaga),
    takeEvery(types.GET_RUNSHEET_REQUEST, getRunsheetSaga),
    takeEvery(types.ADD_INSTRUMENT_RUNSHEET_REQUEST, addInstrumentRunsheetSaga),
    takeEvery(types.DELETE_RUNSHEET_REQUEST, deleteRunsheetSaga),
  ]);
}
