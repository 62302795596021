import { connect } from "react-redux";
import { CgChevronDownO, CgChevronUpO } from "react-icons/cg";
import { setInstrumentRowHeightAndMode } from "../../../actions/instrumentAction";

const MoreButton = ({
  colDef: {
    cellRendererParams: { field, length, isRunsheet, isArray },
  },
  data,
  setInstrumentRowHeightAndMode,
}) => {
  const orgData = data[field] ?? '';
  const columnData = isArray ? data[field].split("|") : data[field];
  let content = (<span>{orgData.substring(0, 15)}</span>);

  const changeMode = () => {
    let height = 50;
    if (!data.expand) {
      height = calculateHeight();
    }
    setInstrumentRowHeightAndMode(data.iid, height, !data.expand, isRunsheet);
  }
  if (isArray && orgData.length > 15) {
    content = (
      <span>
        {!data.expand ? columnData[0].substring(0, 15) : (
          <ul className="no-bullets no-padding">
            {columnData.map((col, i) => (<li className="li-reduce-margin" key={i}>{col}</li>))}
          </ul>
        )}
        <span className="icon">
          {
            data.expand ? (
              <CgChevronUpO
                className="btn-icon"
                onClick={changeMode}
              />
            ) : (
              <CgChevronDownO
                className="btn-icon"
                onClick={changeMode}
              />
            )
          }
        </span>
      </span>
    );
  } else if (!isArray && columnData && columnData.length > 15) {
    content = (<span>{data.expand ? columnData : columnData.substring(0, 15)}
      <span className="icon">
        {
          data.expand ? (
            <CgChevronUpO
              className="btn-icon"
              onClick={changeMode}
            />
          ) : (
            <CgChevronDownO
              className="btn-icon"
              onClick={changeMode}
            />
          )
        }
      </span>
    </span>);
  }

  const calculateHeight = () => {
    const resizableFields = ["qtr", "ge", "gr"];
    const resizableFieldsHeights = resizableFields.map((field) => data[field].split("|").length * 30);
    return Math.max(...resizableFieldsHeights) + 60;
  }
  return (
    <div className="more-button reduce-margin">
      {content}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setInstrumentRowHeightAndMode: (iid, height, isExpand, isRunsheet) => dispatch(setInstrumentRowHeightAndMode(iid, height, isExpand, isRunsheet)),
});

const MoreButtonContainer = connect(
  null,
  mapDispatchToProps
)(MoreButton);

export default MoreButtonContainer;
