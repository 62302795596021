import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginContainer from "./pages/login";
import SignUp from "./pages/login/signup";
import DashboardContainer from "./pages/dashboard";
import UserManagementContainer from "./pages/users";
import OKCRContainer from "./pages/okcr";
import AdvanceSearchNewContainer from "./pages/advancedSearchNew";
import UserLogContainer from "./pages/userLog";
import ErrorLogContainer from "./pages/errorLog";
import MaintenancePage from "./pages/underMaintenance";
import DailyReportContainer from "./pages/dailyReport";
import AxiosConfig from "./AxiosConfig";
import CustomRouter from "./customRoutes";
import history from "./customRoutes/history";
import 'mapbox-gl/dist/mapbox-gl.css';
import { logout } from "./actions/userAction";
import "./App.scss"
import MyProfile from "./pages/myProfile"
import ForgotPasswordPage from "./pages/forgotPassword";
import ResetPasswordPage from "./pages/resetPassword";
import DataLog from "./pages/dataLog";

function App({ user, logout, theme }) {
  const isUnderMaintenance = false; // temp maintenance flag
  const [idleTimeout, setIdleTimeout] = useState(null);
  const privateRoute = (elem) =>
    user.accessToken ? elem : <Navigate to="/login" replace />;
  const authRoute = (elem) =>
    user.accessToken ? <Navigate to="/" replace /> : elem;

  useEffect(() => {
    AxiosConfig.init();
  }, []);

  useEffect(() => {
    // Set up event listeners for user activity
    const time = 1000 * 60 * 60 * 12; // 2 hours
    const resetIdleTimeout = () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      setIdleTimeout(
        setTimeout(() => {
          logout();
        }, time)
      );
    };

    // Attach event listeners to track user activity
    window.addEventListener("click", resetIdleTimeout);
    // Clear timeout and remove event listeners when the component unmounts
    return () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      window.removeEventListener("click", resetIdleTimeout);
    };
    // eslint-disable-next-line
  }, [idleTimeout]);

  return (
    <CustomRouter history={history}>
      <ToastContainer position="top-center" />
      <div className={`body ${theme === 'dark' ? 'body-dark' : 'body-light'}`}>
        <Routes>
          <Route path="/daily-report" element={isUnderMaintenance ? <MaintenancePage /> : <DailyReportContainer />} />
          <Route path="/login" element={authRoute(isUnderMaintenance ? <MaintenancePage /> : <LoginContainer />)} />
          <Route path="/invite/:token" element={authRoute(isUnderMaintenance ? <MaintenancePage /> : <SignUp />)} />
          <Route path="/dash-board" element={privateRoute(<DashboardContainer />)} />
          <Route path="/user-management" element={privateRoute(<UserManagementContainer />)} />
          <Route path="/user-log" element={privateRoute(<UserLogContainer />)} />
          <Route path="/error-log" element={privateRoute(<ErrorLogContainer />)} />
          <Route path="/okcr" element={privateRoute(<OKCRContainer />)} />
          <Route path="/my-profile" element={privateRoute(<MyProfile />)} />
          <Route path="/forgot-password" element={isUnderMaintenance ? <MaintenancePage /> : <ForgotPasswordPage />} />
          <Route path="/reset/:token" element={<ResetPasswordPage />} />
          <Route path="/data-log" element={privateRoute(<DataLog />)} />
          <Route path="/" element={privateRoute(<AdvanceSearchNewContainer />)} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </CustomRouter>
  );
}

const mapStateToProps = (state) => ({ user: state.user, theme: state.user.theme });
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
