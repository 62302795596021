import * as types from "./types";

export const okcrLogin = (loginDetails) => ({
  type: types.OKCR_LOGIN_REQUEST,
  payload: { loginDetails },
});

export const getOkcrList = () => ({
  type: types.OKCR_LISTING_REQUEST,
});

export const getOkcrAccounts = () => ({
  type: types.OKCR_ACCOUNTS_REQUEST,
});

export const deleteUser = (userDetails) => ({
  type: types.OKCR_DELETE_USER_REQUEST,
  payload: { userDetails },
});
