export const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const getTextData = (data) => {
  return data === null || data === undefined || data === "NULL"
    ? ""
    : `${data}`;
};

export const stringifyTRSM = (trsm) => {
  if (trsm.length >= 0) {
    return trsm.map((t) => TRSMToString(t)).join(', ');
  } else {
    return TRSMToString(trsm);
  }
};

const TRSMToString = (trsm) => {
  if (!Object.values(trsm).some((v) => v)) {
    return '';
  }

  if (trsm.section) {
    const sec = trsm.section.match(/\d+/g);
    const sep = trsm.section.match(/[,-]/);
    if (sec) {
      trsm.section = sec[0] === '0' ? '' : sec[0].padStart(2, '0').slice(0, 2);
      if (sep && trsm.section && sec[1] && sec[1] !== '0') {
        trsm.section += sep[0];
        trsm.section += sec[1].padStart(2, '0').slice(0, 2);
      }
    }
  } else {
    trsm.section = '00';
  }

  return `${trsm.township.trim()}-${trsm.range.trim()}-${trsm.section.trim()}`;
};

export const parseTRSMString = (string) => {
  return string.replace(/^[;\s]+|[;\s]+$/g, '').split(',').map((item) => parseTRSM(item));
};

const parseTRSM = (trsm) => {
  let township = '';
  let range = '';
  let section = '';

  const tr = trsm.split('-');
  tr.forEach(el => {
    if (['N', 'S'].includes(el.slice(-1))) {
      township = el.padStart(3, '0');
    } else if (['E', 'W'].includes(el.slice(-1))) {
      range = el.padStart(3, '0');
    } else {
      section = el;
    }
  });

  return { township, range, section };
};

export const numberWithComma = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};