import * as types from "./types";

export const getUserLogList = ( filters ) => ({
    type: types.FETCH_USER_LOG_REQUEST,
    payload: { filters }
});

export const getUsersList = () => ({
    type: types.FETCH_USERS_LIST_REQUEST
});

export const getErrorLogList = (filters) => ({
    type: types.FETCH_ERROR_LOG_REQUEST,
    payload: { filters }
});

export const updateErrorDetails = (data) => ({
    type: types.UPDATE_ERROR_DETAILS_REQUEST,
    payload: { data }
})