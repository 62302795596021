import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { connect } from "react-redux";

const PDFOld = ({ file, zoomLevel, theme }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  return (
    <div className={`pdf ${theme === 'dark' ? 'pdf-dark' : ''}`}>
      {file && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">       
          <div className="btn-rotate">
            <div style={{ padding: '0px 2px' }} className={`rotate-btn ${theme === 'dark' ? 'btn-dark' : 'btn-light'}`}>
              <RotateBackwardButton />
            </div>
            <div style={{ padding: '0px 2px' }} className={`rotate-btn ${theme === 'dark' ? 'btn-dark' : 'btn-light'}`}>
              <RotateForwardButton />
            </div>
          </div>
          <Viewer
            className="viewer"
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance, rotatePluginInstance]}
            defaultScale={zoomLevel ?? SpecialZoomLevel.PageWidth}
            theme={theme === 'dark' ? 'dark' : 'light'}
          />
        </Worker>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(PDFOld);
