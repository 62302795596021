/**
 * User Log Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/userLog";

export function* getUsersList() {
  try {
    const users = yield call(api.doFetchUsersList);
    if (users) {
      yield put({
        type: types.FETCH_USERS_LIST_SUCCESS,
        payload: users,
      });
    } else {
      yield put({
        type: types.FETCH_USERS_LIST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_USERS_LIST_FAILURE,
      payload: { err },
    });
  }
}

export function* getUserLogs({ payload: { filters } }) {
    try {
      const users = yield call(api.doFetchUserLog, filters);
      if (users) {
        yield put({
          type: types.FETCH_USER_LOG_SUCCESS,
          payload: users,
        });
      } else {
        yield put({
          type: types.FETCH_USER_LOG_FAILURE,
          payload: { err: { message: "Something went wrong" } },
        });
      }
    } catch (err) {
      yield put({
        type: types.FETCH_USER_LOG_FAILURE,
        payload: { err },
      });
    }
  }

  export function* getErrorLogs({ payload: { filters } }) {
    try {
      const errors = yield call(api.doFetchErrorLog, filters);
      if (errors) {
        yield put({
          type: types.FETCH_ERROR_LOG_SUCCESS,
          payload: errors,
        });
      } else {
        yield put({
          type: types.FETCH_ERROR_LOG_FAILURE,
          payload: { err: { message: "Something went wrong" } },
        });
      }
    } catch (err) {
      yield put({
        type: types.FETCH_ERROR_LOG_FAILURE,
        payload: { err },
      });
    }
  }

  export function* updateErrorDetails({ payload: { data } }) {
    try {
      const res = yield call(api.doUpdateErrorDetails, data);
      if (res) {
        yield put({
          type: types.UPDATE_ERROR_DETAILS_SUCCESS,
          payload: res[0],
        });
      } else {
        yield put({
          type: types.UPDATE_ERROR_DETAILS_FAILURE,
          payload: { err: { message: "Something went wrong" } },
        });
      }
    } catch (err) {
      yield put({
        type: types.UPDATE_ERROR_DETAILS_FAILURE,
        payload: { err },
      });
    }
  }

// User Log Saga
export default function* userLogSaga() {
  yield all([
    takeEvery(types.FETCH_USERS_LIST_REQUEST, getUsersList),
    takeEvery(types.FETCH_USER_LOG_REQUEST, getUserLogs),
    takeEvery(types.FETCH_ERROR_LOG_REQUEST, getErrorLogs),
    takeEvery(types.UPDATE_ERROR_DETAILS_REQUEST, updateErrorDetails),
  ]);
}
