import axios from "axios";

export const doFetchUsersList = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/user/userlist", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doFetchUserLog = (filters) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/userlogs", filters, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doFetchErrorLog = (filters) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/getreporterror", filters, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const doUpdateErrorDetails = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/updatereporterror", data, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}