/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./SelectViewNew.scss"

class SelectView extends Component {
  customStyles = (theme) => ({
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#46424f" : "#F5F8FA",
      borderRadius: "0.25rem",
      border: "1px solid #CCC",
      ":hover": {
        backgroundColor: theme === "dark" ? "#46424f" : "#F5F8FA",
        cursor: "pointer",
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: theme === "dark" ? "#00aeff" : "#009EF7",
      ":hover": {
        backgroundColor: theme === "dark" ? "#1a1625" : "#F5F8FA",
        cursor: "pointer",
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#1e1e2d" : "#fff",
      color: theme === "dark" ? "#fff" : "#000",
      borderColor: theme === "dark" ? "#ffffff" : "#ced4da",
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme === "dark" ? "#46424f" : "#888",
      },
      "&::-webkit-scrollbar-track": {
        background: theme === "dark" ? "#222" : "#333",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#1e1e2d" : "#fff",
      color: theme === "dark" ? "#fff" : "#000",
    }),
    menuList: (provided) => ({
      ...provided,
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme === "dark" ? "#46424f" : "#888",
      },
      "&::-webkit-scrollbar-track": {
        background: theme === "dark" ? "#222" : "#333",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme === "dark" ? "#46424f" : "#888",
      },
      "&::-webkit-scrollbar-track": {
        background: theme === "dark" ? "#222" : "#333",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme === "dark"
          ? "#46424f"
          : "#e2e2e2"
        : theme === "dark"
        ? "#1e1e2d"
        : "#fff",
      color: theme === "dark" ? "#fff" : "#000",
      ":hover": {
        backgroundColor: theme === "dark" ? "#46424f" : "#e2e2e2",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme === "dark" ? "#fff" : "#000",
    }),
    input: (provided) => ({
      ...provided,
      color: theme === "dark" ? "#fff" : "#000",
    }),
  });

  customOption = (props) => {
    return (
      <div className="filter-option">
        <components.Option {...props} />
        {props.data.removable ? (
          <div
            className="filter-option-remove"
            onClick={() => this.props.onRemoveItem(props.value)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  customFilter(option, searchText) {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  render() {
    const { theme, className, width, label, multiple, searchable, disabled, placeholder, values, options, onChange } = this.props;

    return (
      <div
        className={className}
        style={{
          minWidth: width ? `${width}px` : "112px",
        }}
      >
        <div className="filter-title">{label}</div>
        <div
          className={`${
            className && className.includes("filter") ? "filter-content" : ""
          }`}
        >
          <Select
            className="select"
            classNamePrefix="react-select"
            isMulti={multiple}
            closeMenuOnSelect={!multiple}
            isSearchable={searchable}
            filterOption={this.customFilter}
            isClearable={false}
            hideSelectedOptions={false}
            styles={this.customStyles(theme)}
            isDisabled={disabled}
            components={{
              Option: this.customOption,
            }}
            placeholder={placeholder || label}
            value={values}
            options={options}
            onChange={(e) =>
              onChange(
                e ? (multiple ? e.map((i) => i.value) : [e.value]) : []
              )
            }
          />
        </div>
      </div>
    );
  }
}

export default SelectView;
