import { useState } from "react";
import SelectViewNew from "../../searchFilter/SelectViewNew";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import moment from "moment";
import { parseTRSMString, stringifyTRSM } from "../../../utils";
import { updateErrorDetails } from "../../../actions/userLogAction";

const InstrumentEdit = ({ theme, errorData, instrumentTypes, updateErrorDetails, onCancel }) => {
  const isViewMode = errorData.st === 'Final'; 
  const [correctedData, setCorrectedData] = useState({
    it: errorData.it !== null
      ?
          {
            value: [errorData.it],
            label: [errorData.it],
          }
      : [],
    bn: errorData.bn,
    pn: errorData.pn,
    ge: errorData.ge,
    gr: errorData.gr,
    trs: errorData.trs,
    rd: errorData.rd ? moment(errorData.rd).tz("UTC").format("Y-MM-DD"): '',
    ide: errorData.ide ? moment(errorData.ide).tz("UTC").format("Y-MM-DD"): '',
    ede: errorData.ede ? moment(errorData.ede).tz("UTC").format("Y-MM-DD"): '',
    un: errorData.un,
  });

  const trsmChangeHandler = (e) => {
    if (e.length) {
      const last = e.pop();
      if (last && last.trim() !== "") {
        const modified = stringifyTRSM(parseTRSMString(last.toUpperCase()));
        const trsPattern = /^\d{2}[snSN]-\d{2}[ewEW]-\d{2}$/;
        if(trsPattern.test(modified)) {
          e.push(modified);
        } else {
            alert('Please enter a valid TRS');
        }
      }
    }
    setCorrectedData({ ...correctedData, trs: e })
  };

  return (
    <div className={`edit-instrument${theme === "dark" ? "-dark" : ""}`}>
      <div className="content">
        <label>Instrument Number</label>
        <div>
          <input
            placeholder="Instrument"
            name="iid"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={errorData.iid}
            readOnly
          />
          {/* {errors.iid && (<span className="error">{errors.iid}</span>)} */}
        </div>
        {/* <label>Instrument Type Normalize</label>
          <input
              placeholder="Instrument Type Normalize"
              name="it"
              className="form-control bg-transparent"
              type="text"
              autoComplete="off"
              readOnly
          /> */}
        <label>County</label>
        <div>
          <input
            placeholder="County"
            name="co"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={true}
            value={errorData.co || ''}
          />
        </div>
        <label>Error Description</label>
        <div>
          <input
            placeholder="Error Description"
            name="co"
            className="form-control bg-transparent error-input"
            type="text"
            autoComplete="off"
            readOnly={true}
            value={errorData.ed || ''}
          />
        </div>
        <label>Comment</label>
        <div>
          <textarea
            placeholder="Comment"
            className="form-control bg-transparent error-input"
            readOnly
            value={errorData.cmt}
          />
        </div>
        <label>Instrument Type</label>
        <SelectViewNew
          className="filter error-input"
          options={instrumentTypes.map((item) => ({
            value: item,
            label: item,
          }))}
          values={correctedData.it}
          placeholder="Instrument Type"
          searchable
          disabled={isViewMode}
          onChange={(e) => {
            setCorrectedData({ ...correctedData, it: { value: e, label: e } });
          }}
          width={300}
          theme={theme}
        />
        {/* <label>Instrument Date</label>
          <div>
            <input
              type="date"
              value={errorData.ide}
              className="form-control bg-transparent"
            //   onChange={(e) => setID(e.target.value)}
            />
          </div> */}
        <label>Book</label>
        <div>
          <input
            placeholder="Book"
            name="bn"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={isViewMode}
            value={correctedData.bn || ''}
            onChange={(e) =>
              setCorrectedData({ ...correctedData, bn: e.target.value })
            }
          />
        </div>
        <label>Page</label>
        <div>
          <input
            placeholder="Page"
            name="pn"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={isViewMode}
            value={correctedData.pn || ''}
            onChange={(e) =>
              setCorrectedData({ ...correctedData, pn: e.target.value })
            }
          />
        </div>
        <label>Recorded Date</label>
        <div>
          <input
            type="date"
            readOnly={isViewMode}
            value={correctedData.rd || ''}
            className="form-control bg-transparent"
            onChange={(e) =>
              setCorrectedData({ ...correctedData, rd: e.target.value })
            }
          />
        </div>
        <label> Instrument Date</label>
        <div>
          <input
            type="date"
            readOnly={isViewMode}
            value={correctedData.ide || ''}
            className="form-control bg-transparent"
            onChange={(e) =>
              setCorrectedData({ ...correctedData, ide: e.target.value })
            }
          />
        </div>
        <label>Effective Date</label>
        <div>
          <input
            type="date"
            readOnly={isViewMode}
            value={correctedData.ede || ''}
            className="form-control bg-transparent"
            onChange={(e) =>
              setCorrectedData({ ...correctedData, ede: e.target.value })
            }
          />
        </div>
        <label>TRS</label>
        {/* <div>
        <input
            placeholder="01N-01W-01"
            name="trs"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            readOnly={isViewMode}
            value={correctedData.trs || ''}
            onChange={(e) => setCorrectedData({...correctedData, trs: e.target.value})}
          />
        </div> */}
        <TagsInput
          disabled={isViewMode}
          value={correctedData.trs}
          onChange={trsmChangeHandler}
          addOnBlur
          addKeys={[188, 13]}
          inputProps={{ placeholder: "01N-01W-01" }}
        />
        <label>Grantor's Name</label>
        <TagsInput
          disabled={isViewMode}
          value={correctedData.gr}
        //   addOnBlur
          addKeys={[188, 13]}
          onChange={(value) =>
            setCorrectedData({ ...correctedData, gr: value })
          }
          inputProps={{ placeholder: "Add a Grantor (LastName FirstName)" }}
        />
        <label>Grantee's Name</label>
        <TagsInput
          disabled={isViewMode}
          value={correctedData.ge}
        //   addOnBlur
          addKeys={[188, 13]}
          onChange={(value) =>
            setCorrectedData({ ...correctedData, ge: value })
          }
          inputProps={{ placeholder: "Add a Grantee (LastName FirstName)" }}
        />
        <label>User Note</label>
        <div>
          <textarea
            readOnly={isViewMode}
            placeholder="User Note"
            className="form-control bg-transparent error-input"
            value={correctedData.un || ''}
            onChange={(e) => {
                setCorrectedData({ ...correctedData, un: e.target.value})}}
          />
        </div>
        {/* <label>Effective Date</label>
          <div>
            <input
              type="date"
              readOnly
            //   value={ed}
              className="form-control bg-transparent"
            //   onChange={(e) => setED(e.target.value)}
            />
          </div> */}
      </div>
      <div className="buttons">
        {!isViewMode && (<button
          className="btn btn-primary"
          onClick={() => {
            const data = {
                rid: errorData.Id,
                st: errorData.st === 'Reported' ? 'Corrected' : 'Final',
                it : correctedData.it?.label ? correctedData.it.label[0] : '',
                rd : correctedData.rd,
                ede : correctedData.ede,
                ide : correctedData.ide,
                bn : correctedData.bn,
                pn : correctedData.pn,
                qt : "",
                ge : correctedData.ge.join(", "),
                gr : correctedData.gr.join(", "),
                trs: correctedData.trs.join(" | "),
                un : correctedData.un
            };
            updateErrorDetails(data);
            setTimeout(() => {
                onCancel();
            }, 2000);
          }}
        >
          Save
        </button>)}
        <button className="btn btn-info" onClick={onCancel}>
            Cancel
          </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  instrumentTypes: state.advancedSearch.instrumentTypes,
});

const mapDispatchToProps = (dispatch) => ({
  updateErrorDetails: (data) => dispatch(updateErrorDetails(data)),
});

const InstrumentEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentEdit);

export default InstrumentEditContainer;
