import React from "react";
import { connect } from "react-redux";
import { logout } from "./actions/userAction";

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    this.props.logout();
  }
  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
