/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class SelectView extends Component {
  multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const last =
      selectProps.value.findIndex((selected) => selected.label === label) ===
      selectProps.value.length - 1;
    let displayedString = '';

    if (last) {
      const contentWidth = this.props.width || 80;
      const countofcharactor = contentWidth / 11;

      displayedString = selectProps.value.map((i) => i.label).sort().join(',');

      if (displayedString.length > countofcharactor) {
        displayedString = displayedString.substring(0, countofcharactor) + '...';
      }
    }

    return displayedString;
  };

  customOption = (props) => {
    return (
      <div className="filter-option">
        <components.Option {...props} />
        {props.data.removable ? (
          <div
            className="filter-option-remove"
            onClick={() => this.props.onRemoveItem(props.value)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  customFilter(option, searchText) {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          minWidth: this.props.width ? `${this.props.width}px` : '112px'
        }}
      >
        <div className="filter-title">{this.props.label}</div>
        <div
          className={`${this.props.className && this.props.className.includes('filter')
            ? 'filter-content'
            : ''}`}
        >
          <Select
            className="select"
            classNamePrefix="react-select"
            isMulti={this.props.multiple}
            closeMenuOnSelect={!this.props.multiple}
            isSearchable={this.props.searchable}
            filterOption={this.customFilter}
            isClearable={false}
            hideSelectedOptions={false}
            components={{
              MultiValueContainer: this.multiValueContainer,
              Option: this.customOption
            }}
            placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
            value={this.props.values}
            options={this.props.options}
            onChange={(e) =>
              this.props.onChange(
                e ? (this.props.multiple ? e.map((i) => i.value) : [ e.value ]) : []
              )}
          />
        </div>
      </div>
    );
  }
}

export default SelectView;
