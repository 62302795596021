import * as types from "./types";

export const getDataLog = (filters) => ({
    type: types.FETCH_DATA_LOG_REQUEST,
    payload: { filters }
});

export const getDataError = (filters) => ({
    type: types.FETCH_DATA_ERROR_REQUEST,
    payload: { filters }
});