import * as types from "./types";

export const getInstrumentTypes = () => ({
  type: types.INSTRUMENT_TYPE_LIST_REQUEST,
});

export const getInstrumentList = ( filters ) => ({
  type: types.ADVANCED_SEARCH_REQUEST,
  payload: filters,
});

export const getInstrumentListNew = ( filters, signal, mode ) => ({
  type: types.ADVANCED_SEARCH_NEW_REQUEST,
  payload: {filters, signal , mode},
});

export const setRowHeightAndMode = (index, height, isExpand) => ({
  type: types.SET_ADVANCED_ROW_HEIGHT_AND_MODE,
  payload: { index, height, isExpand },
});

export const getInstrumentPdf = (instrument) => ({
  type: types.GET_ADV_INST_DOCUMENT_REQUEST,
  payload: { instrument },
});

export const downloadInstrumentPdf = (instrument) => ({
  type: types.DOWNLOAD_ADV_INST_DOCUMENT_REQUEST,
  payload: { instrument },
});

export const removeTab = (instrumentId) => ({
  type: types.REMOVE_ADV_TAB,
  payload: { instrumentId },
});

export const setTRSM = (trsm) => ({
  type: types.SET_TRSM_REQUEST,
  payload: { trsm }
});

export const setTags = (filters) => ({
  type: types.SET_TAGS,
  payload: { filters }
});

export const removeTag = (index) => ({
  type: types.REMOVE_TAG,
  payload: { index }
});

export const resetTags = () => ({
  type: types.RESET_TAGS
});

export const udpateFilter = (filters) => ({
  type: types.UPDATE_FILTER_DATA,
  payload: { filters }
});

export const clearFilters = () => ({
  type: types.CLEAR_FILTER_DATA
})

export const getPdfComments = (data) => ({
  type: types.GET_PDF_COMMENTS_REQUEST,
  payload: data
});

export const addPdfComments = (data) => ({
  type: types.ADD_PDF_COMMENTS_REQUEST,
  payload: data
});

export const fetchPreviousFilters = () => ({
  type: types.FETCH_PREVIOUS_FILTERS_REQUEST
});

export const setFilterValues = (index) => ({
  type: types.SET_PREVIOUS_VALUE_IN_FILTER,
  payload: index
});

export const addFilterInPrevious = (filters) => ({
  type: types.ADD_FILTER_IN_PREV_FILTERS,
  payload: filters
});

export const reportIssue = (issue) => ({
  type: types.REPORT_ERROR_PDF_REQUEST,
  payload: issue
});

export const setFirstLoad = (isFirstLoad) => {
  return ({
  type: types.SET_FIRST_LOAD_VALUE,
  payload: { isFirstLoad }
})};

export const getErrorReportData = (id) => ({
  type: types.GET_ERROR_REPORT_DATA_REQUEST,
  payload: { id },
});

export const changeMapMarkerColor = () =>({
  type: types.CHANGE_MARKER_COLOR,
  // payload: { mode },
})