import * as types from "./types";

export const getUsersList = () => ({
  type: types.GET_USERS_LIST_REQUEST,
  payload: {},
});

export const addUser = () => ({
  type: types.ADD_USER,
  payload: {},
});

export const editUser = ( userDetails ) => ({
  type: types.EDIT_USER,
  payload: { userDetails },
});

export const deleteUser = ( userDetails ) => ({
  type: types.DELETE_USER_REQUEST,
  payload: { userDetails },
});

export const udpateUserDetails = ( userDetails ) => ({
  type: types.UDPATE_USER_REQUEST,
  payload: { userDetails },
});

export const udpateUserPassword = ( userDetails ) => ({
  type: types.UPDATE_USER_PASSWORD_REQUEST,
  payload: { userDetails },
});

export const sendInvite = ( email ) => ({
  type: types.SEND_INVITE_TO_USER_REQUEST,
  payload: { email },
});

export const getOkcrUsersList = () => ({
  type: types.GET_OKCR_USERS_LIST_REQUEST,
  payload: {},
});

export const udpateMyPassword = (userDetails) => ({
  type: types.UPDATE_MY_PASSWORD_REQUEST,
  payload: { userDetails },
});

export const updateUserInfo = (userDetails) => {
  return {
    type: types.UPDATE_USER_INFO_REQUEST,
    payload: { userDetails },
  };
};