import { handleActions } from "redux-actions";

const defaultState = {
	__persist: true,
	users: [],
	okcrUsers: [],
	userDetails: {},
	error: null,
	loading: false,
	updating: false,
	updateSuccess: false,
};

export default handleActions(
	{
		GET_USERS_LIST_REQUEST: (state) => {
			return {
				...state,
				loading: true,
				error: null,
			};
		},

		GET_USERS_LIST_SUCCESS: (
			state,
			{ payload: users }
		) => {
			return {
				...state,
				users,
				loading: false,
				error: null,
			};
		},

		GET_USERS_LIST_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},
		GET_OKCR_USERS_LIST_REQUEST: (state) => {
			return {
				...state,
				loading: true,
				okcrUsers: [],
				error: null,
			};
		},

		GET_OKCR_USERS_LIST_SUCCESS: (
			state,
			{ payload: okcrUsers }
		) => {
			const users = okcrUsers.map((user) => {
                return {
                    ...user,
                    rt: user.rt ? new Date(user.rt) : null
                }
            })
			return {
				...state,
				okcrUsers: users,
				loading: false,
				error: null,
			};
		},

		GET_OKCR_USERS_LIST_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},

		ADD_USER: (state) => {
			return {
				...state,
				userDetails: defaultState.userDetails
			};
		},
		EDIT_USER: (state, {payload: { userDetails }}) => {
			return {
				...state,
				userDetails
			}
		},
		UDPATE_USER_REQUEST: (state, { user }) => {
			return {
				...state,
				userDetails: user,
				error: null
			};
		},
		UDPATE_USER_SUCCESS: (state, { payload: { userDetails } }) => {
			const users = state.users;
			const user = users.map((user, i) => ({
			  i,
			  ...user,
			}))
			.find((user) => user.Id === userDetails.Id);
			if (user) users[user.i] = userDetails;
			else users.push(userDetails);
			return {
				...state,
				users,
				userDetails,
				error: null
			};
		},
		UPDATE_USER_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},
		UPDATE_USER_PASSWORD_REQUEST: (state) => {
			return {
				...state,
				error: null
			};
		},
		UPDATE_USER_PASSWORD_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},
		UPDATE_MY_PASSWORD_REQUEST: (state) => {
			return {
				...state,
				error: null,
				updating: true,
				updateSuccess: false,
			};
		},
		UPDATE_MY_PASSWORD_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				error: err.message,
				updating: false,
				updateSuccess: false,
			};
		},
		UPDATE_MY_PASSWORD_SUCCESS: (state) => {
			return{
			 ...state,
			 updating: false,
			 error: null,
			 updateSuccess: true,
			};
		},
		SEND_INVITE_TO_USER_REQUEST: (state) => {
			return {
				...state,
				error: null
			};
		},
		SEND_INVITE_TO_USER_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},
		DELETE_USER_REQUEST: (state) => {
			return {
				...state
			}
		},
		DELETE_USER_SUCCESS: (state, { payload: { userDetails } }) => {
			const users = state.users.filter( user => user.username !== userDetails.username );
			return {
				...state,
				users
			}
		},
		DELETE_USER_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},
	},
	defaultState
);
