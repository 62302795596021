import React, { useState, useEffect } from "react";
import "./forgotPassword.scss";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [localTheme, setLocalTheme] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setLocalTheme(storedTheme);
    }
  }, []);

  const goToLogin = () => navigate("/");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await fetch(
        "https://api.runsheet.com:8081/user/forget",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMessage(
          "A password reset link has been sent to your email. Please check your inbox and spam folder."
        );
      } else {
        setError(data.message || "Invalid User.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div
      className={`forgot-container${
        localTheme === "dark" ? "-dark" : "-light"
      }`}
    >
      {message ? (
        <div className="message-container shadow-box">
          <p>{message}</p>
          <button onClick={goToLogin} className="btn btn-primary">
            Go to Login
          </button>
        </div>
      ) : (
        <div className="forgot-box">
          <h1 className="forgot-h1">Request Password Reset</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label className="forgot-label">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
              />
            </div>
            <button type="submit" className="btn btn-primary button">Send Reset Link</button>
            <button className="btn btn-info mt-2 button" type="button" onClick={goToLogin}>Cancel</button>
          </form>
          {error && <span className="error">{error}</span>}
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordPage;
