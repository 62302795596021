import { useEffect } from "react";
import { connect } from "react-redux";
import { IoMdClose } from "react-icons/io";

import Table from "../common/table";

import { getSavedSheets } from "../../actions/instrumentAction";

function SavedSheets({ getSavedSheets, savedSheets, onSelect, onDelete }) {
  useEffect(() => {
    getSavedSheets();
  }, [getSavedSheets]);

  const cols = [
    {
      headerName: "Runsheet Name",
      field: "rsn",
      flex: 1,
      checkboxSelection: false,
      wrapHeaderText: true,
      minWidth: 200,
      cellRenderer: ({ data }) => (
        // eslint-disable-next-line
        <a href="#" onClick={() => onSelect(data)}>
          {data.rsn}
        </a>
      ),
    },
    {
      headerName: "County",
      field: "co",
      // maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "TRS",
      field: "trs",
      // maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Project Name",
      field: "pn",
      // maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Client Name",
      field: "cn",
      // maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Username",
      // minWidth: 220,
      field: "un",
      wrapHeaderText: true,
    },
    {
      headerName: "Created Date",
      field: "cd",
      maxWidth: 150,
      filter: "agDateColumnFilter",
      wrapHeaderText: true,
      cellRenderer: ({ value }) => (value instanceof Date) ? value.toLocaleDateString("en-US", { timeZone: "UTC" }) : '',
    },
    {
      headerName: "Updated Date",
      field: "ud",
      filter: "agDateColumnFilter",
      maxWidth: 150,
      wrapHeaderText: true,
      cellRenderer: ({ value }) => (value instanceof Date) ? value.toLocaleDateString("en-US", { timeZone: "UTC" }) : '',
    },
    {
      headerName: "",
      resizable: false,
      minWidth: 70,
      maxWidth: 70,
      cellRenderer: ({ data }) => (
        <>
          <IoMdClose
            className="btn-icon"
            onClick={() => onDelete(data, true)}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      cols={cols}
      data={savedSheets}
      rowSelection="none"
    />
  );
}

const mapStateToProps = (state) => ({
  savedSheets: state.instrument.savedSheets,
});

const mapDispatchToProps = (dispatch) => ({
  getSavedSheets: () => dispatch(getSavedSheets()),
});

const SavedSheetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedSheets);

export default SavedSheetsContainer;
