import axios from "axios";

// login user
export const doLogin = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/login", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// user profile
export const doProfile = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/profile")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// logout user
export const doLogout = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/logout", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// signup user
export const doSignup = ({ token, userDetails }) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/signup/"+ token, userDetails)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// refresh token
export const doRefreshToken = (token) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/user/token", { token })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
