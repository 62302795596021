import { CSSTransition } from 'react-transition-group';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal.scss';

const Dialog = ({
  header,
  html,
  footerHtml,
  showModal,
  onHide,
  contentClass = '',
  dialogClass = '',
  theme,
  marginTop = 10
}) => {

  const dialogClassName = `dialog${theme === 'dark' ? '-dark' : ''}`;

  const customStyles = {
    marginTop: `${marginTop}%`
  };

  return (
    <CSSTransition
      in={showModal}
      timeout={300}
      classNames="modal-transition"
      unmountOnExit
    >
      <Modal
        show={showModal}
        onHide={onHide}
        className={`${dialogClassName} custom-modal`}
        contentClassName={contentClass}
        dialogClassName={dialogClass}
        style={customStyles}
        animation={true}
      >
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{html}</Modal.Body>
        {footerHtml && <Modal.Footer>{footerHtml}</Modal.Footer>}
      </Modal>
    </CSSTransition>
  );
};

export default Dialog;