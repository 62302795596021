import * as types from "./types";

export const getInstruments = (trs) => ({
  type: types.GET_INSTRUMENTS_REQUEST,
  payload: { trs },
});

export const getInstrumentPdf = (instrument) => ({
  type: types.GET_INST_DOCUMENT_REQUEST,
  payload: { instrument },
});

export const removeTab = (instrumentId) => ({
  type: types.REMOVE_TAB,
  payload: { instrumentId },
});

export const getSavedSheets = () => ({
  type: types.GET_SAVED_SHEETS_REQUEST,
  payload: {},
});

export const createRunsheet = (runsheet) => ({
  type: types.CREATE_RUNSHEET_REQUEST,
  payload: { runsheet },
});

export const deleteRunsheet = (runsheet) => ({
  type: types.DELETE_RUNSHEET_REQUEST,
  payload: { runsheet },
});

export const getRunsheet = (runsheet) => ({
  type: types.GET_RUNSHEET_REQUEST,
  payload: { runsheet },
});

export const addInstrumentRunsheet = (runsheet) => ({
  type: types.ADD_INSTRUMENT_RUNSHEET_REQUEST,
  payload: { runsheet },
});

export const editRunsheetInstrument = (editRunsheetInstrument) => ({
  type: types.EDIT_RUNSHEET_INSTRUMENT,
  payload: { editRunsheetInstrument },
});

export const addRunsheetInstrument = () => ({
  type: types.ADD_RUNSHEET_INSTRUMENT,
  payload: { },
});

export const handlePdfInstrumentEdit = (id, isOpen) => ({
  type: types.HANDLE_PDF_EDIT,
  payload: { id, isOpen },
});

export const setInstrumentRowHeightAndMode = (index, height, isExpand, isRunsheet) => ({
  type: types.SET_INSTRUMENT_ROW_HEIGHT_AND_MODE,
  payload: { index, height, isExpand, isRunsheet },
});

export const resetTrs = () => ({
  type: types.RESET_TRS
});