import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { IoMdClose, IoMdOpen } from "react-icons/io";
import { CgClose } from "react-icons/cg";
import { MdEditNote, MdArrowCircleDown } from "react-icons/md";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import NavBarContainer from "../../components/common/navbar";
import MoreButton from "../../components/common/moreButton";
import ShowMoreButtonNewContainer from "../../components/common/moreButton/moreButton";
import Modal1 from "../../components/common/modal";
import Table from "../../components/common/table";
import PDF from "../../components/common/pdf/pdfOld";
import SavedSheets from "../../components/savedRunsheet";
import EditInstrumentContainer from "../../components/editInstrument";
import CreateRunsheet from "../../components/createRunsheet";

import {
  getInstruments,
  getInstrumentPdf,
  removeTab,
  getRunsheet,
  addInstrumentRunsheet,
  handlePdfInstrumentEdit,
  deleteRunsheet,
} from "../../actions/instrumentAction";

function Dashboard({
  getInstruments,
  getInstrumentPdf,
  removeTab,
  instruments,
  trs: section,
  pdfData,
  instrumentPDFs,
  savedSheets,
  runsheetInstruments,
  selectedRunsheet,
  getRunsheet,
  addInstrumentRunsheet,
  handlePdfInstrumentEdit,
  loadingPdf,
  deleteRunsheet,
  theme,
  loading
}) {
  const [trs, setTRS] = useState("");
  const [show, setShow] = useState(false);
  const [runShow, setRunShow] = useState(false);
  const [html, setHtml] = useState("");
  const [footerHtml, setFooterHtml] = useState("");
  const [header, setHeader] = useState("");
  const [tab, setTab] = useState("savedRunsheets");
  const [editInstrument, setEditInstrument] = useState(null);
  const [mode, setMode] = useState("");
  const [headerCheck, setHeaderCheck] = useState(false);

  const showModal = (header, html, footerHtml) => {
    setHeader(header);
    setHtml(html);
    setFooterHtml(footerHtml);
    setShow(true);
  };

  const showConfirmationBox = (row, isRunsheet) => {
    showModal(
      "Confirm Deletion",
      `Are you sure you want to delete this ${isRunsheet ? '' : 'instrument from the '}Runsheet?`,
      <>
        <button
          className="btn btn-primary"
          onClick={() => {
            if(isRunsheet) {
              deleteRunsheet({rid: row.rid});
              closeModal();
            } else {
              updateRunsheet(row, false);
              if(headerCheck) setHeaderCheck(false);
            }
          }}
        >
          Yes
        </button>
        <button className="btn btn-info" onClick={closeModal}>
          Cancel
        </button>
      </>
    );
  };

  useEffect(() => {
    let check = true;
    const rsInstruments = runsheetInstruments.map(i => i.iid);
    const insts = instruments.map(i => i.iid);
    insts.forEach(i => {
      if(!rsInstruments.includes(i)) {
        check = false;
        return false;
      }
    });
    setHeaderCheck(check);
  }, [instruments, runsheetInstruments, headerCheck]);

  useEffect(() => {
    if(section) {
      setTab("digital");
    } else {
      setTab("savedRunsheets")
    }
    setTRS(section);
  }, [section])

  const closeModal = () => setShow(false);

  const openInstrument = (instrument, hd) => {
    if (Object.keys(instrumentPDFs).find((k) => k === instrument.iid)) setTab(instrument.iid);
    else {
      getInstrumentPdf(instrument);
    }
  };

  const searchInstrument = () => {
    try {
      let _trs = trs;
      let t = _trs.match(/\d{1,2}[nsNS]{1}/)[0];
      _trs = _trs.replace(t, "");
      t = t.toUpperCase().padStart(3, "0");
      let r = _trs.match(/\d{1,2}[ewEW]{1}/)[0];
      _trs = _trs.replace(r, "");
      r = r.toUpperCase().padStart(3, "0");
      const s = _trs.match(/\d{1,2}/)[0].padStart(2, "0");
      _trs = t + "-" + r + "-" + s;
      setTRS(_trs);

      getInstruments(_trs);
      setTab("digital");
    } catch {}
  };

  const onSelectRunsheet = (runsheet) => {
    // console.log(runsheet, savedSheets);
    getRunsheet(runsheet);
    // getInstruments(savedSheets.find((s) => s.rid === runsheet.rid).trs);
    getInstruments(runsheet.trs);
    setTab("run");
  };

  const onSelect = (row, added) => {
    if (added) updateRunsheet(row, true);
    else showConfirmationBox(row);
  };

  const updateRunsheet = (row, added) => {
    delete row.s;
    addInstrumentRunsheet({
      ...row,
      rid: selectedRunsheet.rid,
      idt: added ? 0 : 1,
    });
    setEditInstrument();
    closeModal();
  };

  const addRunsheet = () => {
    setEditInstrument({});
  }

  const handlePdfEdit = (instrumentId, isOpen) => {
    if(isOpen) {
      const pdfInstrument = instrumentPDFs[instrumentId].instrument;
      const instrument = runsheetInstruments.find(
        (instrument) => instrument.iid === pdfInstrument.iid
      );
      if (!instrument) {
        addInstrumentRunsheet({
          ...pdfInstrument,
          ed: null,
          rid: selectedRunsheet.rid,
          idt: 0,
        });
      }
    }
    handlePdfInstrumentEdit(instrumentId, isOpen);
  }

  const handleHeaderSelectAll = (e) => {
    const isAdd = e.target.checked;
    showModal(
      `${isAdd ? 'Add instruments to Runsheet' : 'Delete instruments from Runsheet'}`,
      `${ isAdd ? 'Are you sure you want to add all the instruments to Runsheet' : 'Are you sure you want to delete all the instruments from Runsheet'}?`,
      <>
        <button
          className="btn btn-primary"
          onClick={() => {
            const rsInstruments = runsheetInstruments.map((r) => r.iid);
            instruments.forEach(instrument => {
              if((isAdd && !rsInstruments.includes(instrument.iid)) || (!isAdd && rsInstruments.includes(instrument.iid))) {
                addInstrumentRunsheet({
                  ...instrument,
                  rid: selectedRunsheet.rid,
                  idt: isAdd ? 0 : 1,
                });
              }
            });
            setHeaderCheck(isAdd);
            closeModal();
          }}
        >
          Yes
        </button>
        <button className="btn btn-info" onClick={closeModal}>
          Cancel
        </button>
      </>
    );
  }

  const cols = [
    {
      headerName: "",
      field: "s",
      hide: !selectedRunsheet,
      headerComponent: () => (
        <input
          type="checkbox"
          checked={headerCheck}
          className="check"
          onChange={handleHeaderSelectAll}
        />
      ),
      cellRenderer: ({ data }) => (
        <input
          aria-hidden="false"
          type="checkbox"
          checked={data.s}
          className="check"
          onChange={(e) => onSelect(data, e.target.checked)}
        />
      ),
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: "Instrument",
      field: "iid",
      cellRenderer: ({ data }) =>
        data.hd ? (
          // eslint-disable-next-line
          <a
            href="#"
            onClick={() => {
              openInstrument(data, true);
              setTab(data.iid);
            }}
          >
            {data.iid}
          </a>
        ) : (
          data.iid
        ),
      width: 110,
      comparator: (otherValue, cellValue) =>
        cellValue.replace(/\D/g, "") >= otherValue.replace(/\D/g, "") ? -1 : 1,
    },
    {
      headerName: "Instrument Type",
      field: "it",
      width: 130,
      wrapHeaderText: true,
    },
    {
      headerName: "County",
      field: "co",
      width: 120,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Date",
      field: "ide",
      maxWidth: 110,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => (value instanceof Date) ? value.toLocaleDateString("en-US", { timeZone: "UTC" }) : '',
    },
    {
      headerName: "Recorded Date",
      field: "rd",
      maxWidth: 110,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => (value instanceof Date) ? value.toLocaleDateString("en-US", { timeZone: "UTC" }) : '',
    },
    { headerName: "Book", field: "bn", width: 90 },
    { headerName: "Page", field: "pn", width: 120 },
    {
      headerName: "Quarter",
      field: "qtr",
      cellRenderer: ShowMoreButtonNewContainer,
      cellRendererParams: {
        field: "qtr",
        length: 1,
        isRunsheet: false,
        isArray: true,
        strLength: 10,
      },
      maxWidth: 120,
    },
    {
      headerName: "Grantors' Name",
      field: "gr",
      cellRenderer: ShowMoreButtonNewContainer,
      cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word' },
      cellRendererParams: {
        field: "gr",
        length: 1,
        isRunsheet: false,
        isArray: true,
        strLength: 20,
      },
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Grantees' Name",
      field: "ge",
      cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word' },
      cellRenderer: ShowMoreButtonNewContainer,
      cellRendererParams: {
        field: "ge",
        length: 1,
        isRunsheet: false,
        isArray: true,
        strLength: 20,
      },
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Document",
      resizable: false,
      filter: false,
      width: 110,
      cellRenderer: ({ data }) => (
          (data.hd || data.co === 'CANADIAN' || data.co === 'Canadian') ? '' : (
              <MdArrowCircleDown
                  className={`btn-icon ${theme === 'dark' ? 'btn-light' : ''}`}
                  onClick={() => {
                      openInstrument(data, false);
                      setTab(data.iid);
                  }}
              />
          )
      ),
  },
  ];

  const colsRunsheet = [
    ...cols.filter((col) => col.field !== "s" && col.headerName !== "Document").map((col) => {
      if(["qtr","ge","gr"].includes(col.field)) {
        return {
          ...col,
          cellRendererParams: {
            ...col.cellRendererParams,
            isRunsheet: true,
            isArray: true
          }
        }
      } else {
        return col;
      }
    }),
    {
      headerName: "Effective Date",
      field: "ed",
      maxWidth: 100,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => (value instanceof Date) ? value.toLocaleDateString("en-US", { timeZone: "UTC" }) : '',
    },
    {
      headerName: "User Notes",
      field: "un",
      width: 150,
      cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word' },
      cellRenderer: MoreButton,
      cellRendererParams: {
        field: "un",
        length: 1,
        isRunsheet: true,
        isArray: false
      },
    },
    {
      headerName: "",
      resizable: false,
      maxWidth: 80,
      cellRenderer: ({ data }) => (
        <>
          <MdEditNote
            className={`btn-icon ${theme === 'dark' ? 'btn-light' : ''}`}
            onClick={() => {
              setEditInstrument(data);
            }}
          />
          <IoMdClose
            className={`btn-icon ${theme === 'dark' ? 'btn-light' : ''}`}
            onClick={() => onSelect(data, false)}
          />
        </>
      ),
    },
  ];

  const tabName = (i) => {
    const instrument = instruments.find((instrument) => instrument.iid === i);
    let name = ''
    if (instrument) {
        name = (instrument.bn && instrument.pn) ? `${instrument.bn}/${instrument.pn.split("-")[0]}` : instrument.iid;
    }
    return name;
}

  return (
    <div className={`dashboard${theme === 'dark' ? '-dark' : ''} d-flex flex-column`}>
      <NavBarContainer />
      <div className="content d-flex flex-column">
        <div className="search mb-1">
          <div className="col-auto">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <BsSearch />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Ex: 14N-12W-01"
                value={trs}
                onChange={(e) => setTRS(e.target.value)}
                onKeyDown={(e) => (e.which === 13 ? searchInstrument() : "")}
              />
            </div>
          </div>
          {/* Get create sheet button only when TRS is searched and no run sheet has already been created */}
          {(
            <button className="btn btn-info" onClick={() => { setMode("create"); setRunShow(true) }}>
              Create Runsheet
            </button>
          )}
        </div>
        <div className="data d-flex flex-column">
          {section ? (
            <Tabs
              defaultActiveKey="digital"
              activeKey={tab}
              onSelect={(iid) => setTab(iid)}
            >
              <Tab eventKey="digital" title="Section Overview">
                <div className="digital d-flex flex-column">
                  <span className="section mb-1">Section: {section}</span>
                  <span className="records mb-3">
                    { loading ? 'Fetching...' : `${instruments.length} records found`}
                  </span>
                  <Table
                    cols={cols}
                    data={instruments}
                    rowSelection="multiple"
                  />
                </div>
              </Tab>
              {/* <Tab eventKey="image" title="Image Index" className="image-tab">
                {loadingPdf ? (
                  <div className="downloading">Downloading PDF...</div>
                ) : (
                  <PDF file={pdfData} zoomLevel={2}/>
                )}
              </Tab> */}
              {selectedRunsheet && (
                <Tab
                  eventKey="run"
                  title="Runsheet"
                  className="run-tab"
                >
                  <div className="digital d-flex flex-column runsheet">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="label mb-2 top-label">Runsheet Name: <span className="records">{selectedRunsheet.rsn}</span></span>
                        <span className="label mb-2 top-label">TRS: <span className="records">{selectedRunsheet.trs}</span></span>
                        <span className="label mb-2 top-label">County: <span className="records">{selectedRunsheet.co}</span></span>
                        <span className="label mb-2 top-label">Username: <span className="records">{selectedRunsheet.un}</span></span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="label mb-2 top-label">Project Name: <span className="records">{selectedRunsheet.pn}</span></span>
                        <span className="label mb-2 top-label">Client Name: <span className="records">{selectedRunsheet.cn}</span></span>
                        <span className="label mb-2 top-label">Mineral Owner: <span className="records">{selectedRunsheet.mo}</span></span>
                        <span className="label mb-2 top-label">Limitations: <span className="records">{selectedRunsheet.li}</span></span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="label mb-2 top-label">Last Book Page Reviewed: <span className="records">{selectedRunsheet.lbpr}</span></span>
                        <span className="label mb-2 top-label">Legal Description: <span className="records">{selectedRunsheet.ld}</span></span>
                        <span className="label mb-2 top-label">From Date: <span className="records">{selectedRunsheet.fd ? selectedRunsheet.fd.toLocaleDateString("en-US", { timeZone: "UTC" }) : ''}</span></span>
                        <span className="label mb-2 top-label">To Date: <span className="records">{selectedRunsheet.td ? selectedRunsheet.td.toLocaleDateString("en-US", { timeZone: "UTC" }) : ''}</span></span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="label mb-2 top-label">Created Date: <span className="records">{selectedRunsheet.cd ? selectedRunsheet.cd.toLocaleDateString("en-US", { timeZone: "UTC" }) : ''}</span></span>
                        <span className="label mb-2 top-label">Updated Date: <span className="records">{selectedRunsheet.ud ? selectedRunsheet.ud.toLocaleDateString("en-US", { timeZone: "UTC" }) : ''}</span></span>
                        <button className={`btn btn-info ${theme === 'dark' ? 'btn-dark' : ''}`} onClick={() => { setMode("edit"); setRunShow(true);}}>Edit</button>
                      </div>
                    </div>
                    {!editInstrument && (<button 
                      className="btn btn-primary add"
                      onClick={addRunsheet}
                    >+</button>
                    )}
                    <Table
                      cols={colsRunsheet}
                      data={[...runsheetInstruments]}
                    />
                    {editInstrument && (
                      <EditInstrumentContainer data={editInstrument} onCancel={() => setEditInstrument(null)}
                      theme={theme}/>
                    )}
                  </div>
                </Tab>
              )}
              {Object.keys(instrumentPDFs).map((i) => (
                <Tab
                  key={i}
                  eventKey={i}
                  title={
                    <div className="inst-tab">
                      {tabName(i)}
                      &nbsp;
                      {instrumentPDFs[i]?.pdf && (
                        <IoMdOpen
                          onClick={() =>
                            window.open(
                              instrumentPDFs[i].pdf,
                              "_blank",
                              "left=100,top=100,width=1200,height=700"
                            )
                          }
                        />
                      )}
                      <CgClose
                        onClick={(e) => {
                          setTab("digital");
                          removeTab(i);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  }
                  className="image-tab"
                >
                  <div className={`${instrumentPDFs[i]?.isEditing && "pdf-container"}`}>
                    {instrumentPDFs[i]?.pdf ? (
                      <>
                        {selectedRunsheet && !instrumentPDFs[i].isEditing && (
                          <MdEditNote
                            className="floater btn-icon"
                            onClick={() => handlePdfEdit(i, true)}
                          />
                        )}
                        <PDF file={instrumentPDFs[i].pdf} zoomLevel={2} />
                      </>
                    ) : (
                      <div className="downloading">Downloading PDF...</div>
                    )}
                    {instrumentPDFs[i].isEditing && (
                      <EditInstrumentContainer data={instrumentPDFs[i].instrument} onCancel={() => handlePdfEdit(i, false)}/>
                    )}
                  </div>
                </Tab>
              ))}
            </Tabs>
          ) : (
            <Tabs
              defaultActiveKey="savedRunsheets"
              activeKey={tab}
              onSelect={(iid) => setTab(iid)}
            >
              <Tab eventKey="savedRunsheets" title="Saved Runsheets">
                <div className="digital d-flex flex-column">
                  <SavedSheets onSelect={onSelectRunsheet} onDelete={showConfirmationBox}/>
                </div>
              </Tab>
            </Tabs>
          )}
        </div>
      </div>

      <Modal1
        showModal={show}
        header={header}
        html={html}
        footerHtml={footerHtml}
        onHide={closeModal}
        theme={theme}
      />
      {runShow && (<CreateRunsheet show={runShow} cancel={() => setRunShow(false)} openRunsheet={onSelectRunsheet} setTab={setTab} mode={mode}/>)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  instruments: state.instrument.instruments || [],
  trs: state.instrument.trs,
  pdfData: state.instrument.pdfData,
  instrumentPDFs: state.instrument.instrumentPDFs,
  loadingPdf: state.instrument.loadingPdf,
  savedSheets: state.instrument.savedSheets,
  selectedRunsheet: state.instrument.selectedRunsheet,
  runsheetInstruments: state.instrument.runsheetInstruments,
  loading: state.instrument.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getInstruments: (trs) => dispatch(getInstruments(trs)),
  getInstrumentPdf: (instrument) => dispatch(getInstrumentPdf(instrument)),
  removeTab: (instrumentId) => dispatch(removeTab(instrumentId)),
  getRunsheet: (runsheetId) => dispatch(getRunsheet(runsheetId)),
  addInstrumentRunsheet: (runsheet) =>
    dispatch(addInstrumentRunsheet(runsheet)),
  handlePdfInstrumentEdit: (instrumentId, isOpen) => dispatch(handlePdfInstrumentEdit(instrumentId, isOpen)),
  deleteRunsheet: (runsheet) => dispatch(deleteRunsheet(runsheet)),
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
