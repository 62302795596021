import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { signup } from "../../actions/userAction";
const SignUp = ({
  signup
}) => {
  const { token } = useParams();

  const defaultValues = useMemo(
    () => ({
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      company: "",
      address: "",
      phone: "",
    }),
    []
  );
  const [user, setUser] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const inputsHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
        setErrors({});
        signup(user, token);
    } else {
        setErrors(formErrors);
    };
};

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!user.username) {
      errors.username = "Username is required";
    } else if (!emailRegex.test(user.username)) {
      errors.username = "Username is not a valid Email";
    }
    if (!user.firstname) {
      errors.firstname = "First Name is required";
    }
    if (!user.lastname) {
      errors.lastname = "Last Name is required";
    }
    if (!user.company) {
      errors.company = "Company is required";
    }
    if (!user.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  return (
    <div className="signup">
      <div className="form">
        <h1 className="d-flex justify-content-center">Sign Up</h1>
        <div className="mb-3">
          <label>Username</label>
          <input
            placeholder="Username"
            name="username"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={user.username}
            onChange={inputsHandler}
          />
          {errors.username && <span className="error">{errors.username}</span>}
        </div>
        <div className="mb-3">
          <label>First Name</label>
          <input
            placeholder="First Name"
            name="firstname"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={user.firstname}
            onChange={inputsHandler}
          />
          {errors.firstname && <span className="error">{errors.firstname}</span>}
        </div>
        <div className="mb-3">
          <label>Last Name</label>
          <input
            placeholder="Last Name"
            name="lastname"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={user.lastname}
            onChange={inputsHandler}
          />
          {errors.lastname && <span className="error">{errors.lastname}</span>}
        </div>
        <div className="mb-3">
          <label>Company</label>
          <input
            placeholder="Company"
            name="company"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={user.company}
            onChange={inputsHandler}
          />
          {errors.company && <span className="error">{errors.company}</span>}
        </div>
        <div className="mb-3">
          <label>Address</label>
          <input
            placeholder="Address"
            name="address"
            className="form-control bg-transparent"
            type="text"
            autoComplete="off"
            value={user.address}
            onChange={inputsHandler}
          />
        </div>
        <div className="mb-3">
          <label>Phone</label>
          <input
            placeholder="Phone"
            name="phone"
            className="form-control bg-transparent"
            type="tel"
            autoComplete="off"
            value={user.phone}
            onChange={inputsHandler}
          />
        </div>
        <div className="fv-row mb-3">
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            name="password"
            className="form-control bg-transparent"
            autoComplete="new-password"
            value={user.password}
            onChange={inputsHandler}
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>
        <div className="d-grid mb-3">
          <button type="button" className="btn btn-primary"onClick={onSubmit}>
            Sign Up
          </button>
        </div>
        <div className="already-member text-center">
          Already a Member?{" "}
          <a className="link-primary" href="/login">
            Log in
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: (userDetails, token) => dispatch(signup(userDetails, token)),
});

const SignUpContainer = connect(null, mapDispatchToProps)(SignUp);

export default SignUpContainer;
