import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './resetPassword.scss';

const ResetPasswordPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [localTheme, setLocalTheme] = useState(""); // Moved inside the component

    useEffect(() => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) {
          setLocalTheme(storedTheme);
        }
      }, []);

    useEffect(() => {
        if (!token) {
            setError('Invalid reset token.');
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (!token) {
            setError('Invalid reset token.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch(`https://api.runsheet.com:8081/user/reset/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ npwd: newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Password has been reset successfully.');
            } else {
                setError(data.message || 'Reset failed. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    const goToLogin = () => navigate('/login');

    return (
        <div className={`reset-container${
            localTheme === "dark" ? "-dark" : ""
          }`}>
            {message ? (
                <div className="message-container">
                    <p>{message}</p>
                    <button onClick={goToLogin} className="btn btn-primary">Go to Login</button>
                </div>
            ) : (
                <div className="reset-box">
                    <h1 className='reset-h1'>Reset Password</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            New Password
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Confirm New Password
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </label>
                        <button type="submit" className='btn btn-primary reset-button'>Reset Password</button>
                    </form>
                    {error && <p className="error">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default ResetPasswordPage;
