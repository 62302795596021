import React from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// created a separate modal specific for this case, as useNavigate was causing table to malfunction on advance search page
const ConfirmationModal = ({ showModal, onHide, getInstruments, theme }) => {

  const dialogClassName = `dialog${theme === 'dark' ? '-dark' : ''}`;
  const navigate = useNavigate();
  return (
    <Modal show={showModal} onHide={onHide} style={{ marginTop: "10%" }}  className={dialogClassName}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>Switch to Section Overview?</Modal.Body>
      <Modal.Footer>
        <div>
          <button
            className="btn btn-primary mr-3"
            onClick={() => {
              getInstruments();
              navigate("/dash-board");
            }}
          >
            Yes
          </button>
          <button className="btn btn-info" onClick={onHide}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(ConfirmationModal);
