import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import ErrorBoundary from "./errorBoundary";
import AppContainer from "./App";
import { RowProvider } from "./contexts/rowContext";

import "./scss/style.scss";

createRoot(document.getElementById("root")).render(
  <Provider store={configureStore()}>
    <ErrorBoundary>
      <RowProvider>
        <AppContainer />
      </RowProvider>
    </ErrorBoundary>
  </Provider>
);
