import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { login } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";

function Login({ login, theme }) {
  const [email, setEmail] = useState("");
  const [pwd, setPWD] = useState("");
  const [localTheme, setLocalTheme] = useState("light"); // Default theme
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch theme from local storage on component mount
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setLocalTheme(storedTheme);
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login(email, pwd);
    }
  };

  const handleForgotNavigate=()=>{
    navigate("/forgot-password")
  }

  return (
    <div className={`login ${localTheme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
      <div className="form">
        <h1 className="d-flex justify-content-center">Sign In</h1>
        <div className="mb-3">
          <label>Email</label>
          <input
            placeholder="Email"
            name="email"
            className="form-control bg-transparent"
            type="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="fv-row mb-3">
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            autoComplete="off"
            name="password"
            className="form-control bg-transparent"
            value={pwd}
            onChange={(e) => setPWD(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="d-flex justify-content-end mb-4">
          <button className="btn btn-link" onClick={handleForgotNavigate}>
            Forgot Password ?
          </button>
        </div>
        <div className="d-grid mb-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => login(email, pwd)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ 
  theme: state.user.theme, // Assuming `theme` is managed in Redux state
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(login(email, password)),
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
