import { handleActions } from "redux-actions";
import * as types from "../actions/types";

const defaultState = {
    dataLogList: [],
	dataErrorList: [],
    loading: false,
    error: null,
};

export default handleActions(
    {
        [types.FETCH_DATA_LOG_REQUEST]: (state) => ({
            ...state,
            dataLogList: [],
            loading: true,
            error: null,
        }),

        [types.FETCH_DATA_LOG_SUCCESS]: (state, { payload: dataLogList }) => ({
            ...state,
            dataLogList,
            loading: false,
        }),

        [types.FETCH_DATA_LOG_FAILURE]: (state, { payload: err }) => ({
            ...state,
            dataLogList: [],
            loading: false,
            error: err,
        }),

		[types.FETCH_DATA_ERROR_REQUEST]: (state) => ({
            ...state,
            dataErrorList: [],
            loading: true,
            error: null,
        }),

        [types.FETCH_DATA_ERROR_SUCCESS]: (state, { payload: dataErrorList }) => ({
            ...state,
            dataErrorList,
            loading: false,
        }),

        [types.FETCH_DATA_ERROR_FAILURE]: (state, { payload: err }) => ({
            ...state,
            dataErrorList: [],
            loading: false,
            error: err,
        }),

        // No separate action for updating default filters
    },
    defaultState
);
